import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { checkIsUserAuthorized, saveTokensToCookies } from "../utils/helpers";

export const useAuthGetData = (request, successLocation, errorLocation) => {
  // External hooks
  const navigate = useNavigate();

  // Local states
  const [isLoading, setIsLoading] = useState(false);

  // Submit auth login and register form
  const requestData = async (params) => {
    setIsLoading(true);

    request(params)
      .then((response) => {
        // Save both tokens to redux store and cookies
        saveTokensToCookies(
          response.data.access,
          response.data.refresh
        );

        if (checkIsUserAuthorized()) {
          navigate(successLocation); // Navigate to the page user was redirecting to
        }
      })
      .catch(() => {
        // If error location provided, navigate user there
        if (errorLocation) {
          navigate(errorLocation);
        }
      })
      .finally(() => setIsLoading(false));
  };

  return { requestData, isLoading };
};