import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getCollections, getRecentlySavedVideos } from "../../../api/videos";

import GalleryHeader from "./components/GalleryHeader";
import GalleryFooter from "./components/GalleryFooter";
import Card from "./components/Card";

import InstallModal from "../../../components/InstallModal";

const GalleryPage = () => {
  // External hooks
  const dispatch = useDispatch();

  // Get videos from redux store
  const videos = useSelector(state => state.videos);

  // Request necessary data from the server if redux store is empty
  useEffect(() => {
    if (!videos.collections.length) {
      getCollections(dispatch);
    }

    if (!videos.recentlySaved.length) {
      getRecentlySavedVideos(dispatch);
    }
  }, []);
  
  return (
    <div className="w-full relative flex flex-col items-center justify-between">
      <div className="w-full flex flex-col items-center">
        <GalleryHeader />
        <div className="flex flex-col items-center justify-self-start w-full">
          <div className="md:pt-[40px] pt-[24px] flex flex-col items-center xl:w-[77%] w-full">
            <p className="text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] font-black px-[20px] md:px-20 self-start">Collections</p>
            <div className="px-[20px] md:px-20 pt-4 w-full flex-wrap gap-x-2 gap-y-5 max-w-[1300px] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4">
              {videos.collections.map((item, index) => (
                <Link key={index} to={'/category/' + item.id}>
                  <div key={item.id}>
                    <Card index={index} title={item.title} />
                  </div>
                </Link>
              ))}
            </div>

            {videos.recentlySaved?.length > 0 && (
              <div className="flex flex-row items-center justify-center self-start px-[20px] md:px-20 pt-10 gap-1 hover:opacity-80 hover:cursor-pointer">
                <Link to="/recently-saved"><p className="text-[24px] font-bold leading-[32px]">Recently saved</p></Link>
                <svg className="mt-1" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M12.7096 9.95379L5.97656 16.3624L7.35544 17.8111L14.8555 10.6725L15.6224 9.94244L14.8498 9.21842L7.34978 2.19037L5.98223 3.64976L12.7096 9.95379Z" fill="#252525"/>
                </svg>
              </div>
            )}

            <div className="px-[20px] md:px-20 py-4 flex flex-row gap-2 flex-nowrap items-start justify-start w-full overflow-scroll scrollbar-hide md:ml-0 pb-[40px]">
              {videos.recentlySaved?.slice(0, 4).map((item, index) =>
                <a key={index} href={item.url} target='_blank' rel="noreferrer" className="bg-[#F8F7F2] min-w-[274px] min-h-[204px] hover:bg-[#F1EFE4] hover:scale-[102%] hover:cursor-pointer max-w-[274px] flex flex-col justify-between rounded-lg p-6">
                  <p className="text-[15px] leading-[24px] font-semibold max-w-[226px]">{item.title}</p>
                  <div className="flex flex-row items-center gap-3 ">
                    <img src={item.channel.logo} alt={item.channel.title} className="h-8 w-8 rounded-[50%]"/>
                    <p className="text-[13px] leading-[20px] font-semibold">{item.channel.title}</p>
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <GalleryFooter />
      <InstallModal />
    </div>
  );
};

export default GalleryPage;
