import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { logEvent } from "firebase/analytics";
import Cookies from "universal-cookie";
import * as amplitude from "@amplitude/analytics-browser";

import { mainApi } from "../utils/api";
import { CODE_SENT } from "../utils/constants";

import analytics from "../libs/googleAnalytics";
import { ANALYTICAL_EVENTS } from "../libs/amplitude";

import AuthInputField from "../components/AuthInputField";
import Button from "../components/Button";
import Spinner from "../components/Spinner";

const cookies = new Cookies();

const Verify = () => {
  // External hooks
  const navigate = useNavigate();
  const state = useRef(useLocation().state);

  // React-hook-form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Local states
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [serverResponse, setServerResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Send the code to the email again if not received
  const handleSendCode = () => {
    mainApi
      .sendCode({ email: state.current.userEmail })
      .then(() => {
        setServerResponse(CODE_SENT);
        setIsButtonDisabled(true);
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 60000); // 60 seconds
      })
  };

  // Send typed code to server and handle the response
  const handleVerify = ({ linkId, code }) => {
    amplitude.track(ANALYTICAL_EVENTS.VERIFICATION);
    setIsLoading(true);

    mainApi.verify({ linkId: linkId, code: code })
      .then((res) => {
        logEvent(analytics, 'sign_up');
        reset();
        setServerResponse("");
        amplitude.track(ANALYTICAL_EVENTS.VERIFICATION_SUCCESS);

        if (res.token) {
          cookies.set('access_token', res.token)
        }

        if (res.redirect_url) {
          window.open(res.redirect_url, "_self");
        } else {
          navigate("/onboarding");
        }
      })
      .catch(() => {
        setVerificationError(true);
        amplitude.track(ANALYTICAL_EVENTS.VERIFICATION_ERROR);
      })
      .finally(() => setIsLoading(false));
  };

  // Submit and send the code to the server
  const onSubmit = (data, e) => {
    e.preventDefault();
    handleVerify({
      linkId: state.current.linkId,
      code: data.code
    });
  };

  // If no variables provided through the state, it means the user got to this
  // page manually, and we redirect him to sign up
  useEffect(() => {
    if (!state.current) {
      navigate("/signup");
    }
  }, [state]);
  
  return (
    <section className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 flex flex-col items-center justify-center gap-6">
      <div>
        <h1 className="text-2xl font-bold text-center">Verify your email</h1>
        <p className="text-sm font-medium text-gray-500 max-w-[320px] text-center leading-5">
          Please check your inbox for a verification code
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
        <AuthInputField
          type="number"
          name="code"
          placeholder="Enter code"
          title="Code sent to"
          extraTitle={state.current?.userEmail ? state.current?.userEmail : "your email"}
          register={register('code', {
            required: {
              value: true,
              message: 'You need to input the verification code'
            },
            minLength: {
              value: 4,
              message: 'Verification code should contain 4 numbers'
            },
            maxLength: {
              value: 4,
              message: 'Verification code should contain 4 numbers'
            },
          })}
          error={
            errors.code?.message ||
            (verificationError && 'Invalid code. Please try again.')}
        />
        <Button type="submit">
          {isLoading ? <Spinner /> : 'Verify'}
        </Button>
      </form>
      <div className="text-sm font-medium text-gray-500">
        <button
          disabled={isButtonDisabled}
          onClick={handleSendCode}
          className={`text-green hover:opacity-60 ${isButtonDisabled && "opacity-60 pointer-events-none"}`}
        >
          Send the code again
        </button> or try a <Link to="/signin" className="text-green hover:opacity-60">
          different account
        </Link>
      </div>
      <p className="text-sm font-medium w-[258px] text-center">
        {serverResponse}
      </p>
    </section>
  );
};

export default Verify;
