import React, {useEffect} from 'react';
import Button from '../../../components/Button';
import {NAME, TERMS_URL, EMAIL} from "../../../utils/constants";
import * as amplitude from "@amplitude/analytics-browser";
import {ANALYTICAL_EVENTS} from "../../../libs/amplitude";
import {shieldLogo} from "../../../images";

const SubsPlan = ({plan}) => {
  useEffect(() => {
  }, [plan])
  
  const {trial_price, trial_price_discount, 
    full_price, credits_count, 
    link, trial_period_in_days, trial_credits, discount_price} = plan[0];

  function handleSubmit(event) {
    event.preventDefault();
    window.open(link);
    amplitude.track("[Web] Paywall | Open Popup");
  }

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="mt-10 flex flex-col items-center justify-center sm:max-w-[480px] w-full">
        <h1 className="mb-4 font-bold text-[24px] leading-[32px]">Subscription plan</h1>
        
        <div className="bg-white w-full rounded-[6px] border py-4 px-5 pb-3">
          <p className="text-[15px] leading-[24px] font-bold">Price Today</p>
          <div className="bg-[#EEEEEE] h-1px my-3"></div>
          <div className="flex flex-row justify-between">
            <div>
              <p className="text-[15px] leading-[24px] font-medium">{trial_period_in_days} days trial</p>
              <p className="text-[13px] leading-[20px] text-gray-500">{trial_credits} credits</p>
            </div>
            <div className="flex flex-row items-start">
              <p className="pr-[8px] font-medium text-[15px] leading-[24px] text-gray-500 line-through">${trial_price}</p>
              <p className="text-[15px] leading-[24px] font-medium">${trial_price_discount}</p>
            </div>
          </div>
        </div>
        
        <p className="py-3 text-[15px] leading-[24px] font-medium">You’ll have {trial_period_in_days} days to see how {NAME} works</p>

        <div className="bg-white w-full rounded-[6px] border py-4 px-5 pb-3">
          <p className="text-[15px] leading-[24px] font-bold">Price after trial</p>
          <div className="bg-[#EEEEEE] h-1px my-3"></div>
          <div className="flex flex-row justify-between">
            <div>
              <p className="text-[15px] leading-[24px] font-medium">Monthly</p>
              <p className="text-[13px] leading-[20px] text-gray-500">{credits_count} credits</p>
            </div>
            <div className="flex flex-col items-end">
              <div className="flex flex-row items-start">
                <p className="pr-[8px] font-medium text-[15px] leading-[24px] text-gray-500 line-through">${Math.round(full_price/31 * 100) / 100}</p>
                <p className="text-[15px] leading-[24px] font-medium">${Math.round(discount_price/31 * 100) / 100} / day</p>
              </div>
              <p className="text-[13px] leading-[20px] text-gray-500">Billed monthly as ${full_price}</p>
            </div>
          </div>
        </div>
        <Button type='submit' onClick={handleSubmit} style={`mt-[28px]`}>
          Continue
        </Button>
      </div>
      <footer className='flex mt-[24px] flex-col items-center'>
        <p className='block font-normal text-center text-lGray text-[11px] leading-[16px] md:w-[440px] w-[300px]'>
          {`By continuing, you agree that if you do not cancel at least 24 hours before the end of the ${trial_period_in_days ?? 3}-days trial,
          you will be charged for a full price ${full_price ? `of $${full_price}` : ""} per selected period until you
          cancel your subscription. You can cancel it in your account or by contacting our customer support team via email at`}
          <a
            className="hover:opacity-60 transition-opacity"
            href={EMAIL}
            rel="noreferrer"
            target="blank"
          >
            &nbsp;support@quicks.ai &nbsp;
          </a><a className='hover:opacity-60 transition-opacity' target='blank' 
          rel="noreferrer" href={TERMS_URL} onClick={() => {
              amplitude.track(ANALYTICAL_EVENTS.PAYWALL3_TERMS)
        }}>Subscription policy</a>
        </p>
        <p className='block mt-[20px] font-medium text-green-text text-[13px] leading-[20px]'>
          {shieldLogo} Pay safe & secure
        </p>
      </footer>
    </div>
  );
};

export default SubsPlan;
