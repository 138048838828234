import React, {useState, useEffect} from 'react';
import {NAME} from "../../utils/constants";
import {useTimer} from "../../hooks/useTimer";
import {clap, mindBlown, saveTime, paywallNewHeader} from "../../images";
import OnboardingSubscriptions from "../../components/OnboardingSubscriptions";
import {PaywallQA} from "./components/PaywallQA";
import {PaywallReviews} from "./components/PaywallReviews";
import Logo from "../../components/Logo";
import {useDispatch, useSelector} from "react-redux";
import {getChannelsPics} from "../../api/videos";

const points = [
  "Avoid wasting time on irrelevant details",
  "Focus on the most important information",
  "Improve your understanding of info",
  "No need to watch the full video to understand the content",
  "Learn more in less time"
];

const Pics = ({channelsPics}) => {
  const url1 = channelsPics[0].image_src;
  const url2 = channelsPics[1].image_src;
  const url3 = channelsPics[2].image_src;
  const url4 = channelsPics[3].image_src;
  const url5 = channelsPics[4].image_src;
  const url6 = channelsPics[5].image_src;
  const url7 = channelsPics[6].image_src;
  return (
    <div className="w-[480px] h-[300px] relative flex flex-row items-center justify-center">
      <svg width="572" height="384" viewBox="0 0 572 384" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_2049_23784)">
          <rect x="81" y="109" width="102" height="102" rx="51" stroke="white" strokeWidth="2" fill="url(#img2)" fillOpacity="1"/>
          <rect x="205" y="91" width="162" height="162" rx="81" stroke="white" strokeWidth="2" fill="url(#img1)" fillOpacity="1"/>
          <rect x="389" y="133" width="102" height="102" rx="51" stroke="white" strokeWidth="2" fill="url(#img3)" fillOpacity="1"/>
          <rect x="361" y="61" width="66" height="66" rx="33" stroke="white" strokeWidth="2" fill="url(#img4)" fillOpacity="1"/>
          <rect x="145" y="217" width="66" height="66" rx="33" stroke="white" strokeWidth="2" fill="url(#img5)" fillOpacity="1"/>
          <rect x="351" y="233" width="50" height="50" rx="25" stroke="white" strokeWidth="2" fill="url(#img6)" fillOpacity="1"/>
          <rect x="171" y="61" width="50" height="50" rx="25" stroke="white" strokeWidth="2" fill="url(#img7)" fillOpacity="1"/>
        </g>
        <defs>
          <pattern id="img1" patternUnits="objectBoundingBox" width="100%" height="100%">
            <image href={url1} x="0" y="0" width="162" height="162" preserveAspectRatio="xMidYMid slice" />
          </pattern>
          <pattern id="img2" patternUnits="objectBoundingBox" width="100%" height="100%">
            <image href={url2} x="0" y="0" width="102" height="102" preserveAspectRatio="xMidYMid slice" />
          </pattern>
          <pattern id="img3" patternUnits="objectBoundingBox" width="100%" height="100%">
            <image href={url3} x="0" y="0" width="102" height="102" preserveAspectRatio="xMidYMid slice" />
          </pattern>
          <pattern id="img4" patternUnits="objectBoundingBox" width="100%" height="100%">
            <image href={url4} x="0" y="0" width="66" height="66" preserveAspectRatio="xMidYMid slice" />
          </pattern>
          <pattern id="img5" patternUnits="objectBoundingBox" width="100%" height="100%">
            <image href={url5} x="0" y="0" width="66" height="66" preserveAspectRatio="xMidYMid slice" />
          </pattern>
          <pattern id="img6" patternUnits="objectBoundingBox" width="100%" height="100%">
            <image href={url6} x="0" y="0" width="50" height="50" preserveAspectRatio="xMidYMid slice" />
          </pattern>
          <pattern id="img7" patternUnits="objectBoundingBox" width="100%" height="100%">
            <image href={url7} x="0" y="0" width="50" height="50" preserveAspectRatio="xMidYMid slice" />
          </pattern>
          <filter id="filter0_d_2049_23784" x="-34" y="-38" width="640" height="460" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="20"/>
            <feGaussianBlur stdDeviation="40"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2049_23784"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2049_23784" result="shape"/>
          </filter>
        </defs>
      </svg>
    </div>
  );
};

const Paywall2 = ({ getPlans, plans }) => {
  // External hooks
  const { timer } = useTimer();
  const dispatch = useDispatch();

  // Local states
  const [checkedPlanId, setCheckedPlanId] = useState('');
  const [checkedPlanFull, setCheckedPlanFull] = useState({});
  const [discount, setDiscount] = useState('30');

  // Data from redux store
  const videos = useSelector(state => state.videos);

  useEffect(() => {
    if (!videos.channelPics.length) {
      getChannelsPics(dispatch);
    }
  }, []);
  
  useEffect(() => {
    setDiscount(checkedPlanFull.discount);
  }, [checkedPlanFull.id]);
  
  useEffect(() => {
    getPlans({page: "paywall2"});
  }, []);
  
  return (
    <div className="page w-full">
      <header className='w-full flex items-center my-[14px] mx-[20px]'>
        <Logo width='32px'></Logo>
        <p className='font-semibold text-[15px] leading-[24px] ml-[8px]'>{NAME}</p>
      </header>
      <div className="flex flex-col items-center px-[12px]">
        {videos.channelPics.length ? <Pics channelsPics={videos.channelPics} /> : null}
        <div className="sm:w-[480px] w-full flex flex-col items-center">
        <h1 className="sm:min-w-[500px] serif font-black text-[40px] leading-[48px] text-center pb-2">Gain access to all contents and save your time</h1>
        <p className="text-gray-500 text-[15px] text-center font-medium pb-10 whitespace-pre-line">Summarize any video on YouTube, and gain access to 10,000 summaries from popular channels</p>
          <div className="bg-my-black h-[42px] w-full rounded-[12px] flex flex-row items-center justify-center mb-2">
            <p className='text-white font-[18px] w-[246px]'>{discount}% discount reserved for <span className="font-bold">{timer}</span></p>
          </div>
          <div className="w-full h-[218px] bg-white rounded-[12px] flex flex-row justify-center gap-4">
            <div className="flex flex-col items-center justify-center w-[170px]">
              <img src={mindBlown} className="w-[120px] h-[120px] mb-2" alt="mind blown icon"/>
              <p className="text-[15px] font-semibold">Hours of video</p>
              <p className="text-[12px] font-medium text-gray-500">too long to watch</p>
            </div>
            
            <svg width="20" height="218" viewBox="0 0 20 218" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="9.5" y="20" width="1" height="63" fill="#333333" fillOpacity="0.08"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M11.1231 101.208L18.2065 108.292L18.9136 108.999L18.2065 109.706L11.1231 116.789L9.70891 115.375L15.0851 109.999H1.66602V107.999H15.0851L9.70891 102.623L11.1231 101.208Z" fill="#262626"/>
              <rect x="9.5" y="135" width="1" height="63" fill="#333333" fillOpacity="0.08"/>
            </svg>

            <div className="flex flex-col items-center justify-center w-[170px]">
              <img src={saveTime} className="w-[120px] h-[120px]" alt="save time icon"/>
              <p className="text-[15px] font-semibold">Save your time</p>
              <p className="text-[12px] font-medium text-gray-500">2 min per video</p>
            </div>
          </div>
          <OnboardingSubscriptions plans={plans} checkedPlanId={checkedPlanId} setCheckedPlanId={setCheckedPlanId} checkedPlanFull={checkedPlanFull} setCheckedPlanFull={setCheckedPlanFull} />
          
          <div className="pt-10 items-center justify-center w-full pb-[60px]">
            <div className="flex flex-col items-center justify-center">
            <img src={clap} alt="clap" className="w-[200px] h-[200px]"/>
            <p className="text-[24px] font-bold text-center mb-5">What you get</p>

            {points.map((point, index) => (<li key={index} className="flex flex-row justify-start items-center gap-2 bg-lBeige h-[56px] w-full px-4 rounded-[12px] mb-1">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M14.7263 4.24185L6.7263 12.0752L6.16067 12.629L5.60091 12.0693L1.26758 7.73593L2.39895 6.60456L6.17252 10.3781L13.6069 3.09863L14.7263 4.24185Z" fill="#21A637"/>
            </svg>
              <p className="text-[15px]">{point}</p>
            </li>))}
            </div>
          </div>
          
          <PaywallQA />
          <p className="text-[24px] font-bold text-center pt-10 pb-6">What people say</p>
          <PaywallReviews />
          
          <p className="text-[18px] font-medium text-center pt-10 pb-12">Become a member<br /> 
            of our global community</p>
          <p className="text-[13px] text-gray-500 text-center pb-[14px]">Copyright &#169;&nbsp;{new Date().getFullYear()} {NAME} All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Paywall2;
