import React from 'react';
import Accordion from "../../../components/Accordion";
import {NAME, EMAIL} from "../../../utils/constants";
import {ANALYTICAL_EVENTS} from "../../../libs/amplitude";
import * as amplitude from "@amplitude/analytics-browser";
import Separator from "../../../components/Separator";

const questions = [
  {
    question: "How does it work?",
    answer: `${NAME} summarizes a video into key points, allowing you to quickly get the main ideas without having to watch the entire video.`,
  },
  {
    question: "How fast is it?",
    answer: `With the power of the AI, ${NAME} gives you key points of your video in less than 30 seconds.`
  },
  {
    question: "How can I save the summary?",
    answer: `You can copy the summary to your clipboard and paste it to your notes. Also it will be saved in your ${NAME}'s account.`
  },
  {
    question: "How much does it cost?",
    answer: `${NAME} uses credits system. Summarizing a video starts from 2 credits and could be more depending on the video's length and content. You can buy credits in the plugin or on our website.`
  },
  {
    question: "Does this plugin work with all videos?",
    answer: `${NAME} works well with YouTube videos that have closed captions. Auto-generated captions could yield poor results. Videos with no captions are not supported.`
  },
  {
    question: "Where can I use this plugin?",
    answer: `Currently ${NAME} is available for Chrome. We're planning to support Firefox, and Safari.`
  }
];


const QA = () => {
  return (
    <section className="flex flex-col items-center md:pb-[176px] pb-[70px] w-full">
      {/*{chatQA}*/}
      <h2 className="pt-2 serif md:text-5xl md:leading-[56px] text-my font-bold text-center md:max-w-lg max-w-[220px]">Questions & Answers</h2>
      <p className="pt-4 pb-12 text-center md:max-w-lg max-w-[220px] text-[18px] leading-[26px] font-regular text-gray-500">Can’t find the answer here? Feel free to <a onClick={() => amplitude.track(ANALYTICAL_EVENTS.CONTACT)} className="underline offset hover:opacity-60 cursor-pointer " href={EMAIL}>Contact us</a></p>
      <div className="bg-white rounded-[12px] px-6 py-10">
        {questions.map((question, index) => <Accordion key={index} question={question} questions={questions} index={index}/>)}
      </div>
    </section>
  );
};

const PaywallQA = () => {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="bg-white rounded-[12px] px-6 py-10">
        <h2 className="text-[24px] pb-8 font-bold text-center">Frequently Asked Questions</h2>
        <Separator />
        {questions.map((question, index) => <Accordion key={index} question={question} questions={questions} index={index}/>)}
      </div>
    </div>
  );
}

export { QA, PaywallQA };
