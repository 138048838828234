import React from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';
import Rollbar from 'rollbar';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_POST_CLIENT_ITEM_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.REACT_APP_NODE_ENV,
  enabled: process.env.REACT_APP_NODE_ENV === 'production'
};

export const rollbar = new Rollbar(rollbarConfig);

const RollbarProvider = ({ children }) => {
  return (
    <Provider instance={rollbar}>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </Provider>
  );
};

export default RollbarProvider;
