import React from 'react';
import {video, videoMob} from "../../../images";

const Video = () => {
  //TODO add video
  return (
    <div className="flex flex-col items-center">
      <div className="md:hidden bg-[#F1EEE0] h-[894px] w-[100vw] flex flex-col items-center">
        <h3 className="text-my font-bold text-center serif w-[335px] pt-[56px]">A tool that is always at hand</h3>
        <p className="pt-2 pb-8 text-[18px] text-[#787670] text-center w-[330px]">Get key insights from videos and use<br />the AI-assistant to ask follow-up questions and learn more</p>
        <video poster={videoMob} className="h-auto w-[278px] object-cover rounded-[16px] border border-[8px]" autoPlay loop muted playsInline>
          <source src="https://s3.eu-north-1.amazonaws.com/docs.sum/video-mob.mp4"
                  type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;"
          />
        </video>
      </div>
    <video poster={video} className="md:mb-[182px] mb-10 sm:max-w-[928px] md:block hidden max-w-[100%] h-auto sm:rounded-lg rounded-none" autoPlay loop muted playsInline>
      <source src="https://s3.eu-north-1.amazonaws.com/docs.sum/video5.mp4"
              type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;"
              />
    </video>
    </div>
  );
};

export default Video;
