import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecentlySavedVideos } from "../../../api/videos";
import {popularSummaries} from "../../../utils/constants";
import GalleryHeader from "./components/GalleryHeader";
import GalleryFooter from "./components/GalleryFooter";
import {Link} from "react-router-dom";

const SummariesDiv = ({item, index}) => {
  const [isSaved, setIsSaved] = useState(false);
  return (
    <div className="bg-[#F8F7F2] md:min-w-[368px] w-full min-h-[244px] hover:bg-[#F1EFE4]  md:max-w-[368px] flex flex-col justify-between rounded-lg p-6" key={index} rel="noreferrer">
      <div className="flex flex-col gap-1">
        <a target='_blank' href={item.url}><p className="hover:cursor-pointer text-[18px] leading-[26px] font-semibold w-full 
        overflow-ellipsis overflow-x-hidden">{item.title}</p></a>
        {item.watched && <div className="flex flex-row items-center justify-start gap-1">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.2194 9.32315C12.7244 8.85801 13.1527 8.38944 13.4808 8.00006C13.1527 7.61069 12.7244 7.14211 12.2194 6.67697C10.9481 5.50615 9.45472 4.60006 8 4.60006C6.54528 4.60006 5.05188 5.50615 3.78062 6.67697C3.27558 7.14211 2.84729 7.61069 2.51923 8.00006C2.84729 8.38944 3.27558 8.85801 3.78062 9.32315C5.05188 10.494 6.54528 11.4001 8 11.4001C9.45472 11.4001 10.9481 10.494 12.2194 9.32315ZM8 13.0001C12.1421 13.0001 15.5 8.00006 15.5 8.00006C15.5 8.00006 12.1421 3.00006 8 3.00006C3.85787 3.00006 0.5 8.00006 0.5 8.00006C0.5 8.00006 3.85787 13.0001 8 13.0001ZM10 7.99941C10 9.10398 9.10457 9.99941 8 9.99941C6.89543 9.99941 6 9.10398 6 7.99941C6 6.89484 6.89543 5.99941 8 5.99941C9.10457 5.99941 10 6.89484 10 7.99941Z" fill="#252525" fill-opacity="0.48"/>
          </svg>
          <p className="text-[#939290] text-[12px] leading-[18px] font-medium">Watched</p>
        </div> }
      </div>

      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-3 ">
          {item.channel.logo && <img src={item.channel.logo} alt={item.channel.title} className="h-8 w-8 rounded-[50%]"/>}
          <div>
            <p className="text-[13px] font-semibold max-w-[150px] overflow-ellipsis overflow-x-hidden">{item.channel.title}</p>
          </div>
        </div>

        {/*<div onClick={() => setIsSaved(!isSaved)} className="cursor-pointer z-10 flex flex-row items-center justify-end gap-1 bg-white border py-[6px] px-3 rounded-[24px] hover:opacity-80">*/}
        {/*  {isSaved ? <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
        {/*    <path d="M3.67057 2.33264H12.3372L13.0039 13.9993L8.00391 11.6322L3.00391 13.9993L3.67057 2.33264Z" fill="#FF5500"/>*/}
        {/*  </svg> : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
        {/*    <path fillRule="evenodd" clipRule="evenodd" d="M12.4673 6V4.13333H14.5007V2.53333H12.4673V0.5H10.8673V2.53333H9.00065V4.13333H10.8673V6H12.4673ZM3.50065 2.5H7.00067V4.1H5.01058L4.58427 11.454L7.34211 10.2085L8.00065 9.91107L8.65919 10.2085L11.417 11.454L11.2168 8H12.8195L13.0628 12.1972L13.1673 14L11.5215 13.2567L8.00065 11.6667L4.47976 13.2567L2.83398 14L2.93849 12.1972L3.50065 2.5Z" fill="#252525"/>*/}
        {/*  </svg>*/}
        {/*  }*/}
        {/*  <p className="text-[13px] leading-5 font-semibold">{isSaved ? "Saved" : "Save"}</p>*/}
        {/*</div>*/}
      </div>

    </div>
  )
}
const SavedPage = () => {
  // External hooks
  const dispatch = useDispatch();

  // Data from redux store
  const videos = useSelector(state => state.videos);

  useEffect(() => {
    // Request recently saved videos if redux store is empty
    if (!videos.recentlySaved.length) {
      getRecentlySavedVideos(dispatch);
    }
  }, []);
  
  return (
    <div className="h-screen w-full flex flex-col items-center justify-between">
      <div className="w-full">
        <GalleryHeader />
        <div className="w-full flex flex-col items-center">
          {videos.recentlySaved?.length > 0 && <p className="self-start xl:self-auto xl:w-[1300px] text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] 
          font-black xl:px-20 md:px-10 px-[20px] md:pt-[60px] pt-[20px] pb-4 md:pb-5">Saved</p>}
          
          <div className="px-[20px] max-w-[1300px] xl:px-20 md:px-10 py-4 flex flex-wrap flex-row gap-x-2 gap-y-5 items-start justify-start w-full md:ml-0 pb-[40px]">
            {videos.recentlySaved?.map((item, index) =>
              <SummariesDiv item={item} index={index} />
            )}
          </div>
        </div>
      </div>
      {videos.recentlySaved?.length === 0 && <div className="flex flex-col items-center justify-center px-4">
        <p className="text-center text-[24px] leading-[32px] text-[#252525] font-[600]">Choose something to read from our collections</p>
        <p className="pb-6 pt-1 text-center text-[15px] leading-[20px] text-[#252525] opacity-60">Your summaries will appear here once you start reading them</p>
        <Link to="/gallery"><button className="hover:opacity-80 active:opacity-80 text-[15px] leading-[24px] font-[500] py-3 px-4 bg-[#F6F6F6] rounded-[6px]">Explore collections</button></Link>
      </div>}
      <GalleryFooter />
    </div>
  );
};

export default SavedPage;
