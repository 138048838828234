import React, {useState, useEffect, useMemo} from 'react';
import SubscriptionPlan from './SubscriptionPlan';
import Button from './Button';
import {TERMS_URL, EMAIL} from "../utils/constants";
import * as amplitude from "@amplitude/analytics-browser";
import {ANALYTICAL_EVENTS} from "../libs/amplitude";
import {shieldLogo} from "../images";
import {useLocation} from "react-router-dom";

const OnboardingSubscriptions = ({plans, checkedPlanId, setCheckedPlanId, checkedPlanFull, setCheckedPlanFull, balancePage}) => {
  const path = useLocation().pathname;
  const [isPlanChecked, setIsPlanChecked] = useState(true);
  const isPaywallFromSummary = useMemo(() => path === '/paywall2', [path]);

  useEffect(() => {
    if (plans.length > 0) {
      setCheckedPlanId(plans[0].id);
      setCheckedPlanFull(plans[0]);
    }
  }, [plans]);
  
  function choosePlan(id) {
      setCheckedPlanId(id);
      setIsPlanChecked(true);

      const currentPlan = plans.filter(item => item.id === id)[0]
      setCheckedPlanFull(currentPlan);
    }

  function handleSubmit(event) {
    event.preventDefault();
    
    if (checkedPlanFull.id) {
      window.open(checkedPlanFull.link);
      amplitude.track("[Web] Paywall | Open Popup");
    }
    else {      
      setIsPlanChecked(false);
      console.log('Сначала необходимо выбрать план ')
    }
  }

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="mt-10 flex flex-col items-center justify-center sm:max-w-[480px] w-full">
        <h1 className={`mb-4 font-bold text-[24px] leading-[32px] ${balancePage && "text-[20px] leading-[28px] self-start"}`}>Choose your plan</h1>
        <div className="flex flex-col w-full items-center justify-center">
            {plans && plans.map((item) => (
                <SubscriptionPlan key={item.id} plan={item} toggleBorder={choosePlan} checkedPlanId={checkedPlanId}/>
            ))}
        </div>
        <Button type='submit' onClick={handleSubmit} style={`mt-[28px]`}>
          Continue
        </Button>
      </div>
      <footer className='flex mt-[24px] flex-col items-center'>
        <p
          className={`block font-normal text-center text-symbolSecondary text-[11px] leading-[16px] max-w-[440px] px-3`}
        >
        {`By continuing, you agree that if you do not cancel at least 24 hours before the end of the ${checkedPlanFull.trial_period_in_days ?? 3}-days trial,
          you will be charged for a full price ${checkedPlanFull.price ? `of $${checkedPlanFull.price}` : ""} per selected period until you
          cancel your subscription. You can cancel it in your account or by contacting our customer support team via email at`}
        <a
          className="hover:opacity-60 transition-opacity"
          href={EMAIL}
          rel="noreferrer"
          target="blank"
        >
          &nbsp;support@quicks.ai &nbsp;
        </a>
        <a className='hover:opacity-60 transition-opacity' target='blank' 
          rel="noreferrer" href={TERMS_URL}>Subscription policy</a>
        </p>
        <p className='block mt-[20px] font-medium text-green-text text-[13px] leading-[20px]'>
          {shieldLogo} Pay safe & secure
        </p>
      </footer>
    </div>
  );
};

export default OnboardingSubscriptions;
