import React from 'react';
import { Link } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";

import { ANALYTICAL_EVENTS } from "../libs/amplitude";

import Auth from "../components/Auth";

const Signup = () => {
  amplitude.track(ANALYTICAL_EVENTS.REGISTER);

  return (
    <Auth title="Sign up" signup>
      <div className="text-[13px] font-medium text-gray-500">
        Already have an account?{' '}
        <Link to="/signin" className="text-green hover:opacity-60">
          Sign in
        </Link>
      </div>
    </Auth>
  );
};

export default Signup;
