import React from 'react';
import CTA from "./CTA";
import {NAME} from "../../../utils/constants";
import {ctaImg} from "../../../images";
import {Link} from "react-router-dom";
import Button from "../../../components/Button";
import {logEvent} from "firebase/analytics";
import analytics from "../../../libs/googleAnalytics";
import * as amplitude from "@amplitude/analytics-browser";
import {ANALYTICAL_EVENTS} from "../../../libs/amplitude";
import {PixelServices} from "../../../libs/pixelServices";

const CTASection = () => {
  function handleClick() {
    logEvent(analytics, 'begin_checkout');
    amplitude.track(ANALYTICAL_EVENTS.CTA_BUTTON_MOBILE);
    PixelServices.registration();
    PixelServices.startTrial();
    PixelServices.lead();
    PixelServices.initCheckout();
  }
  return (
    <section className="w-full max-w-[930px] pt-12 pb-[72px] gap-10 mb-20 flex flex-row flex-wrap items-center md:justify-between justify-center">
      <div className='flex flex-col md:items-start items-center'>
        <p className="pt-4 pb-6 md:text-[56px] md:leading-[64px] text-[40px] leading-[48px] serif font-bold md:text-start text-center">Get the gist in <br />minutes with <br />{NAME}</p>
        <div className="md:w-[360px] w-[335px] hidden md:block"><CTA /></div>
        <div className="md:hidden w-[335px]">
          <Link to="signup" className="md:hidden w-[335px]" onClick={handleClick}>
            <Button>Try Quicks for Mobile</Button>
          </Link>
        </div>
      </div>
      <div className="md:w-[416px] md:h-[233px] w-[335px] h-[187px]">{ctaImg}</div>
    </section>
  );
};

export default CTASection;
