import React, { Children } from 'react';

const Logo = ({width}) => {
  return (
    // <svg width={width} height={width} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path fillRule="evenodd" clipRule="evenodd" d="M29.6668 11.875C29.6668 13.808 24.4435 15.375 18.0002 15.375V16.5417C24.4435 16.5417 29.6668 18.1087 29.6668 20.0417C29.6668 21.9747 24.4435 23.5417 18.0002 23.5417V27.625C11.5568 27.625 6.3335 26.058 6.3335 24.125C6.3335 22.192 11.5568 20.625 18.0002 20.625V19.4583C11.5568 19.4583 6.3335 17.8913 6.3335 15.9583C6.3335 14.0253 11.5568 12.4583 18.0002 12.4583V8.375C24.4435 8.375 29.6668 9.942 29.6668 11.875Z" fill="#282724"/>
    // </svg>
  <svg width={width} height={width} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M28.6666 9.93069C28.6666 11.9711 22.9955 13.6252 15.9999 13.6252V14.6805C22.9955 14.6805 28.6666 16.0983 28.6666 17.8472C28.6666 19.5962 22.9955 21.0139 15.9999 21.0139V25.764C9.00431 25.764 3.33325 24.11 3.33325 22.0696C3.33325 20.0292 9.00431 18.3751 15.9999 18.3751V17.3195C9.00431 17.3195 3.33325 15.9017 3.33325 14.1528C3.33325 12.4039 9.00431 10.9861 15.9999 10.9861V6.23621C22.9955 6.23621 28.6666 7.89028 28.6666 9.93069Z" fill="#2E7356"/>
  </svg>

);
};

export default Logo;
