import React from 'react';
import {point1, point2, point3} from "../../../images";

const Points = () => {
  const points = [
    {
      img: point1,
      title: "Stay Focused",
      text: "Focus on the most important information and avoid wasting time on irrelevant details"
    },
    {
      img: point2,
      title: "Save Time",
      text: "You no longer have to watch the entire video to understand the content"
    },
    {
      img: point3,
      title: "Learn More",
      text: "Improve your understanding and retention of information. Learn more faster"
    }
  ] 
  return (
    <section className="flex flex-row flex-wrap gap-5 items-center justify-evenly md:pt-[181px] md:pb-[184px] py-[120px]">
      {points.map((point, index) => 
        <div className="flex flex-col gap-4 items-center bg-white rounded-[16px] px-[25px] pb-[32px]" key={index}>
          <img src={point.img} alt={point.title} className="h-60 w-[296px]"/>
          <h3 className="text-[24px] leading-[32px] font-bold text-center">{point.title}</h3>
          <p className="text-[15px] text-gray-500 leading-6 text-center max-w-[230px]">{point.text}</p>
        </div>)
      }
    </section>
  );
};

export default Points;
