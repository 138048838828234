import {useState} from 'react';
import {checkMarkDown, checkMarkUp} from "../images";
import Separator from "./Separator";

const Accordion = ({question, questions, index}) => {
  const [isOpen, setIsOpen] = useState(false);
  function handleClick() {
    setIsOpen(!isOpen);
  }
    
  return (
    <div className='overflow-hidden' key={index} onClick={handleClick}>
      <div className="pt-6 flex flex-row justify-between">
        {/*<p className="pb-6 text-[15px] leading-[24px] font-bold">{question.question}</p>*/}
        <p className={` text-[15px] leading-[24px] font-bold transition-all ${isOpen ? 'pb-3' : 'pb-6'}`}>{question.question}</p>
        {isOpen 
          ? <div className="pl-16 hover:opacity-60 cursor-pointer">{checkMarkUp}</div> 
          : <div className="pl-16 hover:opacity-60 cursor-pointer">{checkMarkDown}</div>
        }
      </div>
      {/*{isOpen && <p className="pb-4 text-sm text-lGray text-[15px] leading-[24px] font-normal">{question.answer}</p>}*/}
      <p className={`text-sm text-lGray text-[15px] leading-[24px] font-normal transition-all duration-100 ${isOpen ? 'translate-y-[0] max-h-[200px] pb-4 opacity-100' : 'translate-y-[-50%] max-h-0 pb-0 opacity-0'}`}>{question.answer}</p>
      {index < questions.length - 1 &&  <Separator />}
    </div>
  );
};

export default Accordion;
