import AuthErrorText from "./AuthErrorText";

const AuthInputField = ({
  name,
  title,
  extraTitle,
  register,
  error,
  ...props
}) => {
  return (
    <label htmlFor={name} className="text-[12px] font-medium text-gray-500">
      {title} {extraTitle ? <span className="text-my-black">{extraTitle}</span> : null}
      <input
        id={name}
        {...register}
        {...props}
        className="flex flex-row items-center justify-center rounded-[12px] w-[360px] h-12 bg-white caret custom-outline mt-2 pl-3 text-[15px] font-medium placeholder:text-gray-400 text-my-black"
      />
      {error && <AuthErrorText text={error} customStyle="pt-1" />}
    </label>
  )
}

export default AuthInputField;