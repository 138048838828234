import React from 'react';
import {landingReviews} from "../../../utils/constants";

const Reviews = () => {
  return (
    <section className="flex flex-col items-center md:pb-[180px] pb-[70px]">
      {/*{works}*/}
      <h2 className="pt-2 serif md:text-5xl md:leading-[56px] text-my font-bold text-center md:max-w-lg max-w-[250px]">Works for Everyone</h2>
      <p className="pt-4 pb-12 text-center md:max-w-lg max-w-[300px] text-[18px] leading-[26px] font-regular text-gray-500">Proven tool for students, researchers, professionals and anyone who wants to improve their comprehension</p>
      <div className="md:w-[944px] flex flex-row md:gap-4 gap-0 md:flex-wrap flex-nowrap md:justify-center items-stretch justify-start w-[95%] overflow-scroll scrollbar-hide md:ml-0 ml-[20px]">
        {landingReviews.map((review, index) => 
          <div className="max-w-[296px] min-w-[290px] flex flex-col gap-3 md:mr-0 bg-white h-full border rounded-lg p-5 mr-[8px]" key={index}>
            <div className="flex flex-row gap-3">
              <img src={review.img} alt={review.name} className="h-8 w-8"/>
              <div>
                <p className="text-sm font-medium">{review.name}</p>
                <p className="text-xs text-gray-400">{review.job}</p>
              </div>
            </div>
            <p className="text-[15px] leading-[24px] max-w-[258px]">{review.text}</p>
          </div>
        )}
      </div>
    </section>
  );
};

export { Reviews };
