import { mainApi } from "../utils/api";
import { resetCollections, resetRecentlySaved, addCategory, resetFreeVideos, resetChannelPics } from "../store/videos.slice";

// Request collections from the server and save them to redux store
const getCollections = (dispatch) => {
  mainApi.getCollections()
    .then((res) => {
      const sortedCollections = res.results.sort((a, b) => a.position - b.position);
      // Reset collections state in redux store, so we could use it data next time
      // and not request it from the server again
      dispatch(resetCollections(sortedCollections));
    })
}

// Request recently saved videos from the server and save them to redux store
const getRecentlySavedVideos = (dispatch) => {
  mainApi.getRecentVideos().then((res) => dispatch(resetRecentlySaved(res.results)))
}

// Request videos by collection id
const getVideosByCollection = (dispatch, collectionId) => {
  mainApi.getVideosByCollection(collectionId).then((res) => {
    // Save category data to redux store
    dispatch(addCategory({
      id: collectionId,
      value: res.results
    }));
  })
}

// Request channel pictures from the server
const getChannelsPics = (dispatch) => {
  mainApi.getChannelsPics().then((res) => dispatch(resetChannelPics(res.results)))
}

// Request free videos from the server
const getFreeVideos = (dispatch) => {
  mainApi.getFreeVideos().then((res) => dispatch(resetFreeVideos(res.results)))
}

export {
  getCollections,
  getRecentlySavedVideos,
  getVideosByCollection,
  getChannelsPics,
  getFreeVideos
};