import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from './store/store';

import RollbarProvider from './providers/RollbarProvider';

import App from './App';

import 'inter-ui/inter.css';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <RollbarProvider>
        <App />
      </RollbarProvider>
    </BrowserRouter>
  </Provider>
);
