import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";

const cookies = new Cookies();

// Check if user logged in by checking if toke exists in cookies
const checkIsUserAuthorized = () => {
  const access_token = cookies.get("access_token");

  return Boolean(access_token);
}

// Save access and refresh tokens to cookies
const saveTokensToCookies = (accessToken, refreshToken) => {
  const decodedAccessToken = jwtDecode(accessToken);
  const decodedRefreshToken = jwtDecode(refreshToken);

  cookies.set('access_token', accessToken, {
    expires: new Date(decodedAccessToken.exp * 1000),
    secure: true,
    sameSite: 'none'
  });

  cookies.set('refresh_token', refreshToken, {
    expires: new Date(decodedRefreshToken.exp * 1000),
    secure: true,
    sameSite: 'none'
  });
}

// Get object from array by id
const getObjectById = (id, array) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === id) {
      return array[i];
    }
  }
}

export {
  checkIsUserAuthorized,
  saveTokensToCookies,
  getObjectById
}