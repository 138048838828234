import React from 'react';
import Separator from "../../../../components/Separator";
import {DOWNLOAD_LINK, EMAIL, PRIVACY_URL, TERMS_URL} from "../../../../utils/constants";
import * as amplitude from "@amplitude/analytics-browser";
import {ANALYTICAL_EVENTS} from "../../../../libs/amplitude";

const GalleryFooter = () => {
  return (
    <footer className="w-full px-[20px] md:px-[80px] h-[104px]">
      <Separator />
      <div className="flex flex-row items-center justify-between py-10">
        <div className="flex flex-row items-center md:justify-center justify-between gap-10 w-full md:w-auto">
          <p className="text-[#737373] md:text-[15px] md:leading-[24px] text-[13px] leading-[20px]">&#169;&nbsp;{new Date().getFullYear()} Quicks</p>
          <nav className="flex flex-row items-center gap-4 md:justify-self-end">
            <a href={TERMS_URL} onClick={() => amplitude.track(ANALYTICAL_EVENTS.TERMS)} target="_blank" rel="noopener noreferrer" className="text-[#737373] md:text-[15px] md:leading-[24px] text-[13px] leading-[20px] hover:underline offset">Terms of Use</a>
            <a href={PRIVACY_URL} onClick={() => amplitude.track(ANALYTICAL_EVENTS.PRIVACY)} target="_blank" rel="noopener noreferrer" className="text-[#737373] md:text-[15px] md:leading-[24px] text-[13px] leading-[20px] hover:underline offset">Privacy Policy</a>
            {/*<a href={EMAIL} onClick={() => amplitude.track(ANALYTICAL_EVENTS.CONTACT)} className="text-sm font-bold hover:opacity-60">Contact Us</a>*/}
          </nav>
        </div>
        <a href={DOWNLOAD_LINK} onClick={() => amplitude.track("[Gallery] footer download link")} className="hidden md:flex flex-row items-center justify-center gap-1 hover:opacity-80">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.17275 6.1387C6.99525 4.01769 9.495 3.00293 11.9767 3.00293C15.1927 3.00293 18.378 4.70769 20.0145 7.91847C17.973 7.91922 14.7765 7.91847 13.0448 7.91847C11.7892 7.91922 10.9785 7.89072 10.1003 8.35272C9.06825 8.89573 8.289 9.90298 8.0175 11.0857L5.17275 6.1387ZM9.00525 12.003C9.00525 13.653 10.347 14.9955 11.9963 14.9955C13.6455 14.9955 14.9872 13.653 14.9872 12.003C14.9872 10.353 13.6455 9.00973 11.9963 9.00973C10.347 9.00973 9.00525 10.353 9.00525 12.003ZM13.1573 15.9203C11.4788 16.4198 9.51375 15.8655 8.4375 14.0085C7.61625 12.5903 5.44575 8.80872 4.45875 7.08971C3.46125 8.61972 3 10.3102 3 11.97C3 16.056 5.7945 19.9576 10.2548 20.8336L13.1573 15.9203ZM14.7758 9.00973C16.1737 10.3102 16.479 12.4163 15.531 14.049C14.8177 15.2798 12.54 19.1236 11.436 20.9851C16.8278 21.3173 21 16.965 21 12.006C21 11.0212 20.835 10.011 20.4825 9.00973H14.7758Z" fill="#252525"/>
          </svg>
          <p className="text-[15px] leading-[24px] font-medium">Download Chrome Extension</p>
        </a>
      </div>
    </footer>
  );
};

export default GalleryFooter;
