import { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import {useSelector} from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { init } from '@amplitude/analytics-browser';
import * as amplitude from "@amplitude/analytics-browser";
import { mainApi } from "./utils/api";
import Landing from "./Pages/Landing/Landing";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import Verify from "./Pages/Verify";
import OnboardingPlan from "./Pages/OnboardingPlan";
import Download from "./Pages/Download/Download";
import HowTo from "./Pages/HowTo";
import Paywall from "./Pages/Paywall/Paywall";
import OnboardingPlan2 from "./Pages/OnboardingPlan2";
import Paywall2 from "./Pages/Paywall2/Paywall2";
import Paywall3 from "./Pages/Paywall3/Paywall3";
import GalleryPage from "./Pages/SSR/Gallery/GalleryPage";
import SavedPage from "./Pages/SSR/Gallery/SavedPage"
import CategoryPage from "./Pages/SSR/Gallery/CategoryPage";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  // Local states
  const [plans, setPlans] = useState([]);
  
  // Data from redux store
  const currentUser = useSelector(state => state.user.currentUser);
  
  useEffect(() => {
    let uuid = uuidv4();
    const expirationDate = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000); // expires in 1 year
    const ampUserCookiesArray = document.cookie.match(/amp_user=([\w\d\-]*)/) || [];

    if (ampUserCookiesArray.length === 0) {
      amplitude.track("[Web] | First Launch")
      document.cookie = `amp_user=${uuid};expires=${expirationDate.toUTCString()};domain=.quicks.ai; path=/;`;
    } else {
      uuid = ampUserCookiesArray[1];
    }
    //это не используется нигде надо использовать стор наверно, но в сторе уже есть айди 
    // setCurrentUser(prevState => ({
    //   ...prevState,
    //   id: uuid
    // })
    
    //добавление email в амплитуду
    if (currentUser.email) {
      init(process.env.REACT_APP_AMPLITUDEID, currentUser.email, {deviceId: uuid});
    } else {
      init(process.env.REACT_APP_AMPLITUDEID, null, {deviceId: uuid});
    }
  }, [currentUser.email]);
  
  const currentPath = useLocation().pathname;
  
  const sidebarPages = currentPath === "/welcome" || currentPath === "/main" || currentPath === "/summary" || currentPath === "/error";
  const sidebarClass = "h-[98vh] fixed top-2 right-2 w-352 bg-white border-r-1 border-gray-300 shadow-2xl rounded-md";

  function getPlans({page}) {
    mainApi.getPlans({page}).then((res) => {
      setPlans(res.results);
      console.log(res.results);
    });
  }

  return (
    <main className={sidebarPages ? sidebarClass : ""}>
      <Routes>
        {/* Unprotected routes */}
        <Route path="/" element={<Landing />} />
        <Route path="/signin" element={<Login />}/>
        <Route path="/signup" element={<Signup />}/>
        <Route path="/verify" element={<Verify />}/>
        <Route path='/paywall' element={<Paywall getPlans={getPlans} plans={plans} />} />
        <Route path='/paywall2' element={<Paywall2 getPlans={getPlans} plans={plans} />} />
        <Route path='/paywall3' element={<Paywall3 getPlans={getPlans} plans={plans} />} />

        {/* Protected routes */}
        <Route path="/" element={<ProtectedRoute />}>
          <Route path='/onboarding' element={<OnboardingPlan />} />
          <Route path='/onboarding2' element={<OnboardingPlan2 />} />
          <Route path='/download' element={<Download />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/recently-saved" element={<SavedPage />} />
          <Route path="/category/:collectionId" element={<CategoryPage />} />
          <Route path='/howto' element={<HowTo />} />
        </Route>
      </Routes>
    </main>
  );
}

export default App;