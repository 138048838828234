import React from 'react';
import {paywallReviews} from "../../../utils/constants";

const PaywallReviews = () => {
  return (
    <div className="flex flex-col gap-5">
      {paywallReviews.map((review, index) =>
        <div key={index} className="flex flex-row items-start gap-2">
          <img src={review.img} alt={review.name} className="h-8 w-8"/>
          <div>
            <p className="text-[12px] font-medium text-gray-500 pb-1">{review.name}</p>
            <div className=" flex flex-col gap-4 bg-white rounded-[16px] rounded-tl-none py-4 px-5" key={index}>
              <p className="text-[15px] leading-[24px]">{review.text}</p>
              <div className="flex flex-row gap-1 items-center">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M8.00326 13.9634L13.2035 8.88442C14.6815 7.35997 14.7029 5.12539 13.3302 3.71672C12.0089 2.36131 9.89369 2.36748 8.58036 3.72984L8.00326 4.46845L7.42615 3.72984C6.11282 2.36748 3.99677 2.36131 2.67632 3.71672C1.30362 5.12462 1.32499 7.35997 2.80298 8.88442L8.00326 13.9634Z" fill="#FF5B4D"/>
                </svg>
                <p className="text-[11px] font-medium">{review.likes} likes</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export { PaywallReviews };
