import {
  ava1,
  ava2,
  ava3,
  ava4,
  ava5,
  ava6,
  ava7, ava8, ava9,
  channel1,
  channel2,
  channel3,
  channel4,
  channel5,
  ElonMusk
} from '../images'

const NAME = "Quicks";

const EMAIL = "mailto:support@quicks.ai?subject=Quicks support"
const DOWNLOAD_LINK = "https://chrome.google.com/webstore/detail/summarizer/gfkmhflfkkpapkgdjijoljbdanjdnade";
const TERMS_URL = "https://billing.tryquicks.com/static/terms";
const PRIVACY_URL ="https://billing.tryquicks.com/static/privacy";

const CODE_SENT = "Сode sent. Please check your spam folder. Please wait 60 seconds before sending the code again."

const CREDITS_VIDEO = 55;

const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const YOUTUBE_PATTERN = "^((?:https?:)?\\/\\/)?((?:www|m)\\.)?((?:youtube(-nocookie)?\\.com|youtu.be))(\\/(?!shorts\\/)(?:[\\w\\-]+\\?v=|embed\\/|v\\/)?)([\\w\\-]+)(\\S+)?$";
const questions = [
  {
    id: 1,
    name: "expensive",
    question: "Too expensive",
  },
  {
    id: 2,
    name: "not clear",
    question: "Wasn’t clear how to use it",
  },
  {
    id: 3,
    name: "summary not good",
    question: "The summaries were not good enough",
  },
  {
    id: 4,
    name: "other tools",
    question: "I use other tools",
  },
  {
    id: 5,
    name: "didn't work",
    question: "The extension didn’t work right",
  },
  {
    id: 6,
    name: "not my videos",
    question: "It doesn’t work on the videos I need",
  },
  {
    id: 7,
    name: "intrusive",
    question: "Too intrusive when I browse",
  }
]

const paywallReviews = [
  {
    img: ava1,
    name: "Emily Williams",
    job: "Student",
    text: `I recently started using ${NAME} and I have to say, I am extremely impressed. The interface is user-friendly and easy to navigate. Highly recommended!`,
    likes: 248,
  },
  {
    img: ava2,
    name: "Madison Taylor",
    job: "Marketing Manager",
    text: `${NAME} plugin is great to get the key points from a video without having to watch through the entire thing. Now it's easy to review and refresh memory of the material.`,
    likes: 512,
  },
  {
    img: ava3,
    name: "Alexander Thompson",
    job: "Researcher",
    text: `Now I don't have to spend endless amount of time taking notes from the studies. ${NAME} does it for me in seconds and I can focus on what's really important`,
    likes: 180,
  }
]

const popularSummaries = [
  {
    img: channel5,
    name: 'TED',
    text: 'Dr. Kyle ',
    views: 1480,
    link: 'https://www.youtube.com/ted',
    watched: true,
  },
  {
    img: channel3,
    name: 'Andrew Huberman',
    text: 'Developing a Rational Approach to Supplemen-tation',
    views: 880,
    link: 'https://www.youtube.com/hubermanpodcast'
  },
  {
    img: channel5,
    name: 'The Joe Rogan Experience',
    text: 'Elon Musk talks Twitter, Tesla and how his brain works — live at TED2022',
    views: 3480,
    link: 'https://www.youtube.com/'
  },
  {
    img: channel5,
    name: 'Radiolab',
    text: 'Dr. Kyle ',
    views: 1480,
    link: 'https://www.youtube.com/'
  },
  {
    img: channel3,
    name: 'The Team Ferris Show',
    text: 'Developing a Rational Approach to Supplemen-tation',
    views: 880,
    link: 'https://www.youtube.com/',
    watched: true,
  },
  {
    img: channel5,
    name: 'TED',
    text: 'Elon Musk talks Twitter, Tesla and how his brain works — live at TED2022',
    views: 3480,
    link: 'https://www.youtube.com/'
  },
  {
    img: channel5,
    name: 'TED',
    text: 'Dr. Kyle ',
    views: 1480,
    link: 'https://www.youtube.com/'
  },
  {
    img: channel3,
    name: 'Andrew Huberman',
    text: 'Developing a Rational Approach to Supplemen-tation',
    views: 880,
    link: 'https://www.youtube.com/'
  },
  {
    img: channel5,
    name: 'TED',
    text: 'Elon Musk talks Twitter, Tesla and how his brain works — live at TED2022',
    views: 3480,
    link: 'https://www.youtube.com/'
  }
]

const landingReviews = [
  {
    img: ava4,
    name: "Bella Chu",
    job: "@Lunabelle",
    text: `Thanks to this product, I've been able to cut my study time in half!😱 The summaries are so helpful and make it easy to understand complex concepts`,
    likes: 248,
  },
  {
    img: ava5,
    name: "Alexander Kim",
    job: "@kim_alex",
    text: `I can ask questions and get clarification on concepts I don't fully understand while watching some videos`,
    likes: 512,
  },
  {
    img: ava6,
    name: "Isabella Rodriguez",
    job: "@pixelpenguin",
    text: `As a researcher, I can't imagine working without this product. It's helped me find relevant information so much faster, which has made my work much more efficient 👍`,
    likes: 180,
  },
  {
    img: ava7,
    name: "Mia Davis",
    job: "@miadavis789",
    text: "The summaries are so well-written and easy to understand. I've never been great at taking notes, but this product has made it so much easier to keep track of important information",
    likes: 352,
  },
  {
    img: ava8,
    name: "Emily Williams",
    job: "@millyws89",
    text: "Instead of reading through lengthy textbooks or watching hours-long lectures, I can extract summaries from videos and podcasts with just a few clicks 🔥",
    likes: 413,
  },
  {
    img: ava9,
    name: "Lucas Nguyen",
    job: "@lg_987",
    text: "I don't have a lot of free time, so this product has been a real game-changer for me. I can quickly get the information I need and move on to other tasks",
  }
]

const mockData = {
  title: "Elon Musk talks Twitter, Tesla and how his brain works — live at TED2022",
  url: "https://www.youtube.com/embed/nm1TxQj9IsQ",
  author: "TED",
  poster: ElonMusk,
}

const mockUser = {
  name: 'John Smith',
  credits: 15,
}

const mockKeypoints = [
  {
    chapter: 'Introduction',
    time: '00:25', 
    text: 'The first puberty occurs during the first three months of life and is caused by hormones dhea.'
  },
  {
    chapter: 'Neuron architecture',
    time: '00:35',
    text: 'Voting is essential for democracy and freedom, while robots will replace some jobs and Elon Musk discussed buying Twitter and the movie Ted in an 80 minute interview.',
    text2: 'The first puberty occurs during the first three months of life and is caused by hormones dhea.'
  },
  {
    chapter: 'Neuron architecture',
    time: '00:45',
    text: 'The first puberty occurs during the first three months of life and is caused by hormones dhea.'
  },
  {
    chapter: 'Neuron architecture',
    time: '00:55',
    text: 'The first puberty occurs during the first three months of life and is caused by hormones dhea.'
  },
  {
    chapter: 'Neuron architecture',
    time: '01:25',
    text: 'The first puberty occurs during the first three months of life and is caused by hormones dhea.'
  },
  {
    chapter: 'Neuron architecture',
    time: '02:25',
    text: 'The first puberty occurs during the first three months of life and is caused by hormones dhea.'
  },
  {
    chapter: 'Neuron architecture',
    time: '00:25',
    text: 'The first puberty occurs during the first three months of life and is caused by hormones dhea.'
  },
  {
    chapter: 'Neuron architecture',
    time: '00:35',
    text: 'The first puberty occurs during the first three months of life and is caused by hormones dhea.'
  },
  {
    chapter: 'Neuron architecture',
    time: '00:45',
    text: 'The first puberty occurs during the first three months of life and is caused by hormones dhea.'
  },
  {
    chapter: 'Neuron architecture',
    time: '00:55',
    text: 'The first puberty occurs during the first three months of life and is caused by hormones dhea.'
  },
  {
    chapter: 'Neuron architecture',
    time: '01:25',
    text: 'The first puberty occurs during the first three months of life and is caused by hormones dhea.'
  },
  {
    time: "02:25",
    text: 'The first puberty occurs during the first three months of life and is caused by hormones dhea.'
  },
]

const mockMessages = {
  bot: [
    {
      id: 1,
      text: "Hi, I'm a Quicks bot. I'm here to help you with your questions about this video. How can I help you?",
    },
    {
      id: 2,
      text: "What do you want to know about this video?",
    }
],
  user: [
    {
      id: 1,
      text: "Hi, I'm a user. I'm here to ask qustions. How can you help me?",
    },
    {
      id: 2,
      text: "What do you want to tell me about this video?",
    }
  ]
}

const channels = [
  {
    image: channel1,
    title: 'Lex Fridman'
  },
  {
    image: channel2,
    title: 'PowerfulJRE'
  },
  {
    image: channel3,
    title: 'Andrew H.'
  },
  {
    image: channel4,
    title: 'Jordan B. P.'
  },
  {
    image: channel5,
    title: 'TED'
  }
]

const cardColors = [
  {
    //синий
    "mainColor": "bg-[#BFDBFE]",
    "secondColor": "to-[#3B82F6]"
  },
  {
    //оранж
    "mainColor": "bg-[#FED7AA]",
    "secondColor": "to-[#FB923C]"
  },
  {
    //зел
    "mainColor": "bg-[#BBF7D0]",
    "secondColor": "to-[#22C55E]"
  },
  {
    //розовый
    "mainColor": "bg-[#F5D0FE]",
    "secondColor": "to-[#E879F9]"
  },
  {
    //зел пастельный
    "mainColor": "bg-[#A7F3D0]",
    "secondColor": "to-[#34D399]"
  },
  {
    //салмон
    "mainColor": "bg-[#FECACA]",
    "secondColor": "to-[#F87171]"
  },
  {
    //бирюз
    "mainColor": "bg-[#BAE6FD]",
    "secondColor": "to-[#38BDF8]"
  },
  {
    //seabreeze
    "mainColor": "bg-[#99F6E4]",
    "secondColor": "to-[#2DD4BF]"
  },
  {
    //желтый
    "mainColor": "bg-[#FEF08A]",
    "secondColor": "to-[#FBBF24]"
  },
  {
    //индиго
    "mainColor": "bg-[#C7D2FE]",
    "secondColor": "to-[#818CF8]"
  },
  {
    //серый
    "mainColor": "bg-[#E2E8F0]",
    "secondColor": "to-[#64748B]"
  },
  {
    //фиолет
    "mainColor": "bg-[#DDD6FE]",
    "secondColor": "to-[#9333EA]"
  }
]


export { NAME, CREDITS_VIDEO, EMAIL_PATTERN, paywallReviews, landingReviews, mockData, mockUser, mockKeypoints, channels, EMAIL, questions, mockMessages, popularSummaries, DOWNLOAD_LINK, YOUTUBE_PATTERN, TERMS_URL, PRIVACY_URL, CODE_SENT, cardColors }