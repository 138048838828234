const Button = ({
  type,
  onClick,
  disabled,
  style,
  light,
  children
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={type || "button"}
      className={`${light ? "bg-lightGreen text-button-green hover:opacity-70" : "bg-green text-white hover:bg-dark-green"} px-4 text-[15px] leading-[24px] font-medium h-[52px] rounded-[12px] w-full flex justify-center items-center ${style}`}
    >
      {children}
    </button>
  );
};

export default Button;
