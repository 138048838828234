import Cookies from "universal-cookie";
import { mainApi } from "../utils/api";
import { resetCurrentUser } from "../store/user.slice";

const cookies = new Cookies();

// Request user info from the server and save it to redux store
const getUserInfo = (dispatch) => {
  mainApi.getUserInfo().then((response) => {
    // Save user info to redux store
    dispatch(resetCurrentUser(response.result));
  });
}

// Logout user by removing tokens from cookies and clearing localStorage
const logoutUser = (navigate) => {
  mainApi.logout();
  localStorage.clear();

  // Remove tokens for all domains
  cookies.remove('access_token', { path: '/' });
  cookies.remove('access_token', { path: '/', domain: '.quicks.ai' });
  cookies.remove('amp_user', { path: '/' });
  cookies.remove('amp_user', { path: '/', domain: '.quicks.ai' });
  cookies.remove('refresh_token', { path: '/' });

  navigate("/signin");
}

export {
  getUserInfo,
  logoutUser
};