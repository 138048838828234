import React from 'react';
import {NAME} from "../../../utils/constants";
import {ANALYTICAL_EVENTS} from "../../../libs/amplitude";
import {chrome} from "../../../images";
import {Link} from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import {PixelServices} from "../../../libs/pixelServices";
import analytics from "../../../libs/googleAnalytics";
import {logEvent} from "firebase/analytics";

const CTA = () => {
  function handleClick() {
    logEvent(analytics, 'begin_checkout');
    amplitude.track(ANALYTICAL_EVENTS.CTA_BUTTON);
    PixelServices.registration();
    PixelServices.startTrial();
    PixelServices.lead();
    PixelServices.initCheckout();
  }
  return (
    <Link to="/signup" onClick={handleClick}><button className="w-full bg-green hover:bg-dark-green text-[15px] text-white font-medium py-[14px] px-10 rounded-lg flex flex-row justify-center gap-2">{chrome} Try {NAME} for Chrome
    </button></Link>
  );
};

export default CTA;
