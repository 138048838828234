import * as amplitude from "@amplitude/analytics-browser";
import { NAME } from "../utils/constants";
import { ANALYTICAL_EVENTS } from "../libs/amplitude";
import Logo from '../components/Logo';

const OnboardingPlan = ({navigateTo}) => {
  amplitude.track(ANALYTICAL_EVENTS.ONBOARDING_QUESTION);

  const handleClick = (event) => {
    event.preventDefault()
    window.location.replace("https://plan.quicks.ai/web-paywall/02d9fe9f-6da6-4e1f-802f-f4a59a183249")
  }

  return (
    <div className="page">
      <header className='flex items-center my-[14px] mx-[20px]'>
        <Logo width='32px'></Logo>
        <p className='font-semibold text-[15px] leading-[24px] ml-[8px]'>{NAME}</p>
      </header>
      <section className="flex flex-1 flex-col items-center justify-center">
        <h1 className='serif mb-[24px] font-black text-center text-[24px] leading-[32px] sm:text-[32px] sm:leading-[40px]'>How are you planning <br /> to use {NAME}?</h1>
        <button onClick={handleClick} className='block bg-white w-[350px] sm:w-[360px] h-48 mb-[12px] rounded-[12px] font-medium text-[15px] leading-[24px] hover:opacity-60 transition-opacity' type='submit'>Work</button>
        <button onClick={handleClick} className='block bg-white w-[350px] sm:w-[360px] h-48 mb-[12px] rounded-[12px] font-medium text-[15px] leading-[24px] hover:opacity-60 transition-opacity' type='submit'>Pleasure</button>
        <button onClick={handleClick} className='block bg-white w-[350px] sm:w-[360px] h-48 mb-[12px] rounded-[12px] font-medium text-[15px] leading-[24px] hover:opacity-60 transition-opacity' type='submit'>Studies</button>
      </section>
    </div>
  );
};

export default OnboardingPlan;
