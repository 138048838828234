import React from 'react';

const Separator = () => {
  return (
    <div className="h-1px bg-gray-300">
    </div>
  );
};

export default Separator;
