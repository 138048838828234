import {channels} from '../../../utils/constants.js';
import {promoImg} from "../../../images";
import {youtube, approveIcon} from '../../../images';
import CTA from "./CTA";
import Button from "../../../components/Button";
import {Link} from "react-router-dom";
import React from "react";
import {logEvent} from "firebase/analytics";
import analytics from "../../../libs/googleAnalytics";
import * as amplitude from "@amplitude/analytics-browser";
import {ANALYTICAL_EVENTS} from "../../../libs/amplitude";
import {PixelServices} from "../../../libs/pixelServices";

const Promo = () => {
  function handleClick() {
    logEvent(analytics, 'sign_up');
    // logEvent(analytics, 'purchase');
    logEvent(analytics, 'begin_checkout');
    amplitude.track(ANALYTICAL_EVENTS.CTA_BUTTON_MOBILE);
    PixelServices.registration();
    PixelServices.startTrial();
    PixelServices.lead();
    PixelServices.initCheckout();
  }
  return (
    <div className="pb-[84px]">
      <section className="md:pt-[67px] pt-4 px-[20px] flex xl:flex-row flex-col-reverse items-center justify-between md:gap-16 gap-10">
      <div>
        <h1 className="serif md:text-6xl text-[48px] leading-[56px] font-bold max-w-[449px] md:leading-[64px] ">Quick Insights from YouTube Videos</h1>
        <p className="pt-4 pb-6 md:text-lg text-[18px] md:max-w-[449px] max-w-[316px] text-gray-500 leading-6">Unlock a powerful summarization tool that helps you save your time and quickly extract key-points and takeaways from any video</p>
        <div className="md:w-[360px] w-[335px] hidden md:block"><CTA /></div>
        <div className="md:hidden w-[335px]">
          <Link to="signup" className="md:hidden w-[335px]" onClick={handleClick}>
            <Button>Try Quicks for Mobile</Button>
          </Link>
        </div>
        </div>
        <div className="md:h-[389px] md:w-[375px] w-[280px] h-[315px] mobile">
          {promoImg}
        </div>
      </section>
      <div className="px-[20px] md:pb-[120px]">
        <p className="pt-6 pb-[16px] font-bold text-[15px] leading-[24px]">Ideal works with the best {youtube} authors and channels:</p>
        <div className="pt-2 flex flex-row flex-wrap md:gap-2 gap-3 items-center">
          {channels.map((channel, index) => 
            <div key={index} className="flex flex-row gap-2 p-1 pr-3 bg-white border rounded-[50px] pr-[14px] items-center">
              <img src={channel.image} alt="channel" className="w-[40px] h-[40px] object-cover"/>
              <p className="text-[11px] text-center leading-[16px] min-w-max font-medium flex flex-row items-center">{channel.title}{approveIcon}</p>
            </div>
          )}
          <p className="pl-2 text-sm text-[10px] leading-[18px] text-gray-500 font-bold min-w-max">100 000+ MORE</p>
        </div>
      </div>
    </div>
  );
};

export default Promo;
