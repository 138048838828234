const ANALYTICAL_EVENTS = {
  LANDING_LAUNCH : '[Landing] Landing Launch',

  LOGIN : '[Landing] Login page launch',
  LOGIN_BUTTON : '[Landing] Landing Login button',
  LOGIN_CODE : '[Landing] Login code sent',
  LOGIN_ERROR: '[Landing] Login error',

  REGISTER : '[Landing] Register page launch',
  REGISTER_CODE : '[Landing] Register code sent',
  REGISTER_ERROR: '[Landing] Register error',
  CTA_BUTTON : '[Landing] CTA Register Button',
  CTA_BUTTON_MOBILE: '[Landing] CTA Register Button Mobile',

  VERIFICATION: '[Landing] Verification',
  VERIFICATION_ERROR: '[Landing] Verification error',
  VERIFICATION_SUCCESS: '[Landing] Verification success',

  QUITFORM_LAUNCH: '[Landing] Quit Form Launch',
  QUITFORM_SUBMIT: '[Landing] Quit Form Submit',
  QUITFORM_SUCCESS: '[Landing] Quit Form Success',

  QUITFORM_FEEDBACK: '[Landing] Quit Form Feedback',
  QUITFORM_ERROR: '[Landing] Quit Form Error',
  REINSTALL_BTN: '[Landing] Reinstall button',

  ERROR_404: '[Landing] Error 404',

  READER: '[Landing] Reader page launch',

  SIDEBAR_MAIN: '[Sidebar] Sidebar main page',
  SIDEBAR_ERROR: '[Sidebar] Sidebar error',
  SIDEBAR_SUMMARY: '[Sidebar] Sidebar summary page',
  SIDEBAR_WELCOME: '[Sidebar] Sidebar welcome page',

  TERMS: '[Landing] Terms open',
  PRIVACY: '[Landing] Privacy open',
  CONTACT: '[Landing] Contact support open',

  ONBOARDING_QUESTION : '[Web] Onboarding | Question',
  ONBOARDING_DOWLOAD_PAGE : '[Web] Onboarding | Download',
  ONBOARDING_DOWLOAD_BTN : '[Web] Onboarding | Download Button',
  ONBOARDING_INSTRUCTION : '[Web] Onboarding | Instruction Page',

  CANCEL_SUBS_BTN1 : '[Web] Cancel reason: no benefits',
  CANCEL_SUBS_BTN2 : '[Web] Cancel reason: too expensive',
  CANCEL_SUBS_BTN3 : '[Web] Cancel reason: not for me',
  CANCEL_SUBS_BTN4 : '[Web] Cancel reason: other',

  TRY_AGAN_BTN1: '[Web] Try again: Very likely',
  TRY_AGAN_BTN2: '[Web] Try again: Quite likely',
  TRY_AGAN_BTN3: '[Web] Try again: Hard to say',
  TRY_AGAN_BTN4: '[Web] Try again: Unlikely',
  TRY_AGAN_BTN5: '[Web] Try again: Very Unlikely',
  
  PAYWALL : '[Web] Paywall page',
  PAYWALL2 : '[Web] Paywall2 page',
  PAYWALL3 : '[Web] Paywall3 page',
  PAYWALL_TRY_SUBSCRIBE : '[Web] Paywall| Try subscribe',
  PAYWALL2_TRY_SUBSCRIBE : '[Web] Paywall2| Try subscribe',
  PAYWALL_SUBS_TYPE : '[Web] Paywall | Subscription type',
  PAYWALL2_SUBS_TYPE : '[Web] Paywall2 | Subscription type',
  PAYWALL_TERMS : '[Web] Paywall | Terms',
  PAYWALL2_TERMS : '[Web] Paywall2 | Terms',
  PAYPRO_REDIRECT_EVENT : '[Web] Paypro redirect',
  PAYPRO2_REDIRECT_EVENT : '[Web] Paywall2 Paypro redirect',

  PROFILE_PAGE : '[Web] Profile page',
  PROFILE_EDIT : '[Web] Profile edit',
  UNSUBSCRIBED : '[Web] Unsubscribed',
}
export {ANALYTICAL_EVENTS};