import { useEffect, useState } from "react";
import {useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCollections, getVideosByCollection } from "../../../api/videos";
import GalleryHeader from "./components/GalleryHeader";
import GalleryFooter from "./components/GalleryFooter";
import {getObjectById} from "../../../utils/helpers";

const SummariesDiv = ({item, index}) => {
  return (
    <a target='_blank' href={item.url} rel="noreferrer" className="w-full md:min-w-[368px] md:max-w-[368px] min-h-[244px]">
    <div className="bg-[#F8F7F2] md:min-w-[368px] w-full min-h-[244px] hover:bg-[#F1EFE4]  md:max-w-[368px] flex flex-col justify-between rounded-lg p-6" key={index} rel="noreferrer">
      <div className="flex flex-col items-start">{index === 0 && <svg className="mb-3" width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.9375 35.9947L12.8463 35.2353C12.8463 35.2353 11.4483 35.2091 11.3504 33.9997C11.2045 32.2014 12.8227 29.9582 9.22879 26.215C8.03644 28.8161 8.73843 31.1352 9.63045 32.5241C8.73919 31.1335 7.03394 29.4584 4.17344 29.4585C5.10256 32.268 7.57165 34.2212 9.78561 35.2224C11.0179 35.7776 12.1697 36.0413 12.9375 35.9947ZM7.04865 29.1957L7.36996 28.5167C7.36996 28.5167 6.53905 28.1614 6.85933 27.4349C7.51276 25.9491 9.75186 24.8641 8.50292 20.3612C6.44676 21.9126 5.88751 24.1919 5.99935 25.6728C5.88827 24.1902 5.37823 21.9925 3.11321 20.7704C2.25442 25.5668 6.5474 28.8875 7.04865 29.1957ZM5.37213 21.4736L6.00047 21.3925C6.00047 21.3925 5.45793 20.6656 5.91411 20.1169C6.84704 18.9921 9.05444 18.609 9.08848 14.4047C6.9252 15.2363 5.83605 17.1127 5.56768 18.4238C5.83774 17.1135 5.98245 15.0426 4.32346 13.4232C2.35066 17.3893 5.28932 21.3769 5.37213 21.4736ZM6.27829 13.3053C6.27924 13.9485 6.22601 14.1835 6.22601 14.1835L6.69253 14.4333C6.69253 14.4333 6.702 13.6948 7.23549 13.3362C8.32766 12.6038 10.3301 12.8319 11.4295 9.21347C9.34924 9.37866 7.99315 10.7895 7.42643 11.8534C7.99315 10.7895 8.58361 8.96918 7.56504 7.15168C5.17326 10.0816 6.2777 11.9889 6.27829 13.3053ZM10.2566 5.59384C9.19136 6.8669 9.09693 8.6055 9.09693 8.6055C9.09693 8.6055 10.5936 8.13356 11.6182 6.9686C12.5773 5.87609 12.8686 4 12.8686 4C12.8686 4 11.2393 4.42219 10.2566 5.59384Z" fill="url(#paint0_linear_1842_23617)"/>
        <path d="M30.6953 27H27.3555V15.9746H27.2773L24.1035 17.9375V15.0371L27.5898 12.8594H30.6953V27Z" fill="url(#paint1_linear_1842_23617)"/>
        <path d="M43.0625 35.9947L43.1537 35.2353C43.1537 35.2353 44.5517 35.2091 44.6496 33.9997C44.7955 32.2014 43.1773 29.9582 46.7712 26.215C47.9636 28.8161 47.2616 31.1352 46.3695 32.5241C47.2608 31.1335 48.9661 29.4584 51.8266 29.4585C50.8974 32.268 48.4284 34.2212 46.2144 35.2224C44.9821 35.7776 43.8303 36.0413 43.0625 35.9947ZM48.9513 29.1957L48.63 28.5167C48.63 28.5167 49.461 28.1614 49.1407 27.4349C48.4872 25.9491 46.2481 24.8641 47.4971 20.3612C49.5532 21.9126 50.1125 24.1919 50.0007 25.6728C50.1117 24.1902 50.6218 21.9925 52.8868 20.7704C53.7456 25.5668 49.4526 28.8875 48.9513 29.1957ZM50.6279 21.4736L49.9995 21.3925C49.9995 21.3925 50.5421 20.6656 50.0859 20.1169C49.153 18.9921 46.9456 18.609 46.9115 14.4047C49.0748 15.2363 50.164 17.1127 50.4323 18.4238C50.1623 17.1135 50.0176 15.0426 51.6765 13.4232C53.6493 17.3893 50.7107 21.3769 50.6279 21.4736ZM49.7217 13.3053C49.7208 13.9485 49.774 14.1835 49.774 14.1835L49.3075 14.4333C49.3075 14.4333 49.298 13.6948 48.7645 13.3362C47.6723 12.6038 45.6699 12.8319 44.5705 9.21347C46.6508 9.37866 48.0069 10.7895 48.5736 11.8534C48.0069 10.7895 47.4164 8.96918 48.435 7.15168C50.8267 10.0816 49.7223 11.9889 49.7217 13.3053ZM45.7434 5.59384C46.8086 6.8669 46.9031 8.6055 46.9031 8.6055C46.9031 8.6055 45.4064 8.13356 44.3818 6.9686C43.4227 5.87609 43.1314 4 43.1314 4C43.1314 4 44.7607 4.42219 45.7434 5.59384Z" fill="url(#paint2_linear_1842_23617)"/>
        <defs>
          <linearGradient id="paint0_linear_1842_23617" x1="7.96875" y1="4" x2="7.96875" y2="36" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D97706"/>
            <stop offset="1" stopColor="#FBBF24"/>
          </linearGradient>
          <linearGradient id="paint1_linear_1842_23617" x1="28" y1="8" x2="28" y2="32" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D97706"/>
            <stop offset="1" stopColor="#FBBF24"/>
          </linearGradient>
          <linearGradient id="paint2_linear_1842_23617" x1="48.0312" y1="4" x2="48.0312" y2="36" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D97706"/>
            <stop offset="1" stopColor="#FBBF24"/>
          </linearGradient>
        </defs>
      </svg>
      }
      {index === 1 && <svg className="mb-3" width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.9375 35.9947L12.8463 35.2353C12.8463 35.2353 11.4483 35.2091 11.3504 33.9997C11.2045 32.2014 12.8227 29.9582 9.22879 26.215C8.03644 28.8161 8.73843 31.1352 9.63045 32.5241C8.73919 31.1335 7.03394 29.4584 4.17344 29.4585C5.10256 32.268 7.57165 34.2212 9.78561 35.2224C11.0179 35.7776 12.1697 36.0413 12.9375 35.9947ZM7.04865 29.1957L7.36996 28.5167C7.36996 28.5167 6.53905 28.1614 6.85933 27.4349C7.51276 25.9491 9.75186 24.8641 8.50292 20.3612C6.44676 21.9126 5.88751 24.1919 5.99935 25.6728C5.88827 24.1902 5.37823 21.9925 3.11321 20.7704C2.25442 25.5668 6.5474 28.8875 7.04865 29.1957ZM5.37213 21.4736L6.00047 21.3925C6.00047 21.3925 5.45793 20.6656 5.91411 20.1169C6.84704 18.9921 9.05444 18.609 9.08848 14.4047C6.9252 15.2363 5.83605 17.1127 5.56768 18.4238C5.83774 17.1135 5.98245 15.0426 4.32346 13.4232C2.35066 17.3893 5.28932 21.3769 5.37213 21.4736ZM6.27829 13.3053C6.27924 13.9485 6.22601 14.1835 6.22601 14.1835L6.69253 14.4333C6.69253 14.4333 6.702 13.6948 7.23549 13.3362C8.32766 12.6038 10.3301 12.8319 11.4295 9.21347C9.34924 9.37866 7.99315 10.7895 7.42643 11.8534C7.99315 10.7895 8.58361 8.96918 7.56504 7.15168C5.17326 10.0816 6.2777 11.9889 6.27829 13.3053ZM10.2566 5.59384C9.19136 6.8669 9.09693 8.6055 9.09693 8.6055C9.09693 8.6055 10.5936 8.13356 11.6182 6.9686C12.5773 5.87609 12.8686 4 12.8686 4C12.8686 4 11.2393 4.42219 10.2566 5.59384Z" fill="url(#paint0_linear_1842_23634)"/>
        <path d="M22.8438 24.6074L28.0098 20.0566C28.459 19.6465 28.8138 19.2982 29.0742 19.0117C29.3411 18.7188 29.5462 18.4258 29.6895 18.1328C29.8327 17.8333 29.9043 17.5111 29.9043 17.166C29.9043 16.7819 29.8132 16.4499 29.6309 16.1699C29.4551 15.8835 29.2109 15.6621 28.8984 15.5059C28.5859 15.3496 28.2344 15.2682 27.8438 15.2617C27.4336 15.2682 27.0788 15.3529 26.7793 15.5156C26.4798 15.6719 26.2454 15.9062 26.0762 16.2188C25.9069 16.5247 25.8223 16.8893 25.8223 17.3125H22.6582C22.6582 16.3685 22.873 15.5482 23.3027 14.8516C23.7324 14.1484 24.3379 13.6081 25.1191 13.2305C25.9069 12.8529 26.8184 12.6641 27.8535 12.6641C28.9082 12.6641 29.8327 12.8431 30.627 13.2012C31.4277 13.5527 32.043 14.0508 32.4727 14.6953C32.9023 15.3398 33.1172 16.0788 33.1172 16.9121C33.1172 17.4785 33.0065 18.0221 32.7852 18.543C32.5703 19.0573 32.1829 19.6335 31.623 20.2715C31.0632 20.9095 30.2786 21.6712 29.2695 22.5566L27.4727 24.2168V24.3047H33.3027V27H22.8535L22.8438 24.6074Z" fill="url(#paint1_linear_1842_23634)"/>
        <path d="M43.0625 35.9947L43.1537 35.2353C43.1537 35.2353 44.5517 35.2091 44.6496 33.9997C44.7955 32.2014 43.1773 29.9582 46.7712 26.215C47.9636 28.8161 47.2616 31.1352 46.3695 32.5241C47.2608 31.1335 48.9661 29.4584 51.8266 29.4585C50.8974 32.268 48.4284 34.2212 46.2144 35.2224C44.9821 35.7776 43.8303 36.0413 43.0625 35.9947ZM48.9513 29.1957L48.63 28.5167C48.63 28.5167 49.461 28.1614 49.1407 27.4349C48.4872 25.9491 46.2481 24.8641 47.4971 20.3612C49.5532 21.9126 50.1125 24.1919 50.0007 25.6728C50.1117 24.1902 50.6218 21.9925 52.8868 20.7704C53.7456 25.5668 49.4526 28.8875 48.9513 29.1957ZM50.6279 21.4736L49.9995 21.3925C49.9995 21.3925 50.5421 20.6656 50.0859 20.1169C49.153 18.9921 46.9456 18.609 46.9115 14.4047C49.0748 15.2363 50.164 17.1127 50.4323 18.4238C50.1623 17.1135 50.0176 15.0426 51.6765 13.4232C53.6493 17.3893 50.7107 21.3769 50.6279 21.4736ZM49.7217 13.3053C49.7208 13.9485 49.774 14.1835 49.774 14.1835L49.3075 14.4333C49.3075 14.4333 49.298 13.6948 48.7645 13.3362C47.6723 12.6038 45.6699 12.8319 44.5705 9.21347C46.6508 9.37866 48.0069 10.7895 48.5736 11.8534C48.0069 10.7895 47.4164 8.96918 48.435 7.15168C50.8267 10.0816 49.7223 11.9889 49.7217 13.3053ZM45.7434 5.59384C46.8086 6.8669 46.9031 8.6055 46.9031 8.6055C46.9031 8.6055 45.4064 8.13356 44.3818 6.9686C43.4227 5.87609 43.1314 4 43.1314 4C43.1314 4 44.7607 4.42219 45.7434 5.59384Z" fill="url(#paint2_linear_1842_23634)"/>
        <defs>
          <linearGradient id="paint0_linear_1842_23634" x1="7.96875" y1="4" x2="7.96875" y2="36" gradientUnits="userSpaceOnUse">
            <stop stopColor="#6B7280"/>
            <stop offset="1" stopColor="#94A3B8"/>
          </linearGradient>
          <linearGradient id="paint1_linear_1842_23634" x1="28" y1="8" x2="28" y2="32" gradientUnits="userSpaceOnUse">
            <stop stopColor="#6B7280"/>
            <stop offset="1" stopColor="#94A3B8"/>
          </linearGradient>
          <linearGradient id="paint2_linear_1842_23634" x1="48.0312" y1="4" x2="48.0312" y2="36" gradientUnits="userSpaceOnUse">
            <stop stopColor="#6B7280"/>
            <stop offset="1" stopColor="#94A3B8"/>
          </linearGradient>
        </defs>
      </svg>
      }
      {index === 2 && <svg className="mb-3" width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.9375 35.9947L12.8463 35.2353C12.8463 35.2353 11.4483 35.2091 11.3504 33.9997C11.2045 32.2014 12.8227 29.9582 9.22879 26.215C8.03644 28.8161 8.73843 31.1352 9.63045 32.5241C8.73919 31.1335 7.03394 29.4584 4.17344 29.4585C5.10256 32.268 7.57165 34.2212 9.78561 35.2224C11.0179 35.7776 12.1697 36.0413 12.9375 35.9947ZM7.04865 29.1957L7.36996 28.5167C7.36996 28.5167 6.53905 28.1614 6.85933 27.4349C7.51276 25.9491 9.75186 24.8641 8.50292 20.3612C6.44676 21.9126 5.88751 24.1919 5.99935 25.6728C5.88827 24.1902 5.37823 21.9925 3.11321 20.7704C2.25442 25.5668 6.5474 28.8875 7.04865 29.1957ZM5.37213 21.4736L6.00047 21.3925C6.00047 21.3925 5.45793 20.6656 5.91411 20.1169C6.84704 18.9921 9.05444 18.609 9.08848 14.4047C6.9252 15.2363 5.83605 17.1127 5.56768 18.4238C5.83774 17.1135 5.98245 15.0426 4.32346 13.4232C2.35066 17.3893 5.28932 21.3769 5.37213 21.4736ZM6.27829 13.3053C6.27924 13.9485 6.22601 14.1835 6.22601 14.1835L6.69253 14.4333C6.69253 14.4333 6.702 13.6948 7.23549 13.3362C8.32766 12.6038 10.3301 12.8319 11.4295 9.21347C9.34924 9.37866 7.99315 10.7895 7.42643 11.8534C7.99315 10.7895 8.58361 8.96918 7.56504 7.15168C5.17326 10.0816 6.2777 11.9889 6.27829 13.3053ZM10.2566 5.59384C9.19136 6.8669 9.09693 8.6055 9.09693 8.6055C9.09693 8.6055 10.5936 8.13356 11.6182 6.9686C12.5773 5.87609 12.8686 4 12.8686 4C12.8686 4 11.2393 4.42219 10.2566 5.59384Z" fill="url(#paint0_linear_1842_23651)"/>
        <path d="M27.9672 27.179C27.0127 27.179 26.1626 27.0149 25.4168 26.6868C24.6753 26.3544 24.0894 25.8984 23.659 25.3189C23.2329 24.7351 23.0134 24.0618 23.0006 23.299H25.7876C25.8046 23.6186 25.909 23.8999 26.1008 24.1428C26.2968 24.3814 26.5567 24.5668 26.8806 24.6989C27.2045 24.831 27.5688 24.897 27.9736 24.897C28.3955 24.897 28.7684 24.8224 29.0922 24.6733C29.4161 24.5241 29.6697 24.3175 29.8529 24.0533C30.0361 23.7891 30.1278 23.4844 30.1278 23.1392C30.1278 22.7898 30.0297 22.4808 29.8337 22.2124C29.642 21.9396 29.365 21.7266 29.0028 21.5732C28.6448 21.4197 28.2187 21.343 27.7243 21.343H26.5035V19.3104H27.7243C28.142 19.3104 28.5106 19.2379 28.8302 19.093C29.154 18.9482 29.4055 18.7479 29.5844 18.4922C29.7634 18.2322 29.8529 17.9297 29.8529 17.5845C29.8529 17.2564 29.7741 16.9688 29.6164 16.7216C29.463 16.4702 29.2457 16.2741 28.9644 16.1335C28.6874 15.9929 28.3635 15.9226 27.9928 15.9226C27.6178 15.9226 27.2748 15.9908 26.9637 16.1271C26.6526 16.2592 26.4033 16.4489 26.2158 16.696C26.0283 16.9432 25.9282 17.233 25.9154 17.5653H23.2627C23.2755 16.8111 23.4907 16.1463 23.9083 15.571C24.3259 14.9957 24.8884 14.5462 25.5958 14.2223C26.3074 13.8942 27.1107 13.7301 28.0056 13.7301C28.909 13.7301 29.6995 13.8942 30.377 14.2223C31.0546 14.5504 31.5809 14.9936 31.9559 15.5518C32.3351 16.1058 32.5226 16.728 32.5184 17.4183C32.5226 18.1513 32.2947 18.7628 31.8344 19.2528C31.3785 19.7429 30.784 20.054 30.051 20.1861V20.2884C31.0141 20.4119 31.7471 20.7464 32.2499 21.2919C32.757 21.8331 33.0084 22.5107 33.0042 23.3246C33.0084 24.0703 32.7932 24.733 32.3586 25.3125C31.9282 25.892 31.3337 26.348 30.5752 26.6804C29.8167 27.0128 28.9474 27.179 27.9672 27.179Z" fill="url(#paint1_linear_1842_23651)"/>
        <path d="M43.0625 35.9947L43.1537 35.2353C43.1537 35.2353 44.5517 35.2091 44.6496 33.9997C44.7955 32.2014 43.1773 29.9582 46.7712 26.215C47.9636 28.8161 47.2616 31.1352 46.3695 32.5241C47.2608 31.1335 48.9661 29.4584 51.8266 29.4585C50.8974 32.268 48.4284 34.2212 46.2144 35.2224C44.9821 35.7776 43.8303 36.0413 43.0625 35.9947ZM48.9513 29.1957L48.63 28.5167C48.63 28.5167 49.461 28.1614 49.1407 27.4349C48.4872 25.9491 46.2481 24.8641 47.4971 20.3612C49.5532 21.9126 50.1125 24.1919 50.0007 25.6728C50.1117 24.1902 50.6218 21.9925 52.8868 20.7704C53.7456 25.5668 49.4526 28.8875 48.9513 29.1957ZM50.6279 21.4736L49.9995 21.3925C49.9995 21.3925 50.5421 20.6656 50.0859 20.1169C49.153 18.9921 46.9456 18.609 46.9115 14.4047C49.0748 15.2363 50.164 17.1127 50.4323 18.4238C50.1623 17.1135 50.0176 15.0426 51.6765 13.4232C53.6493 17.3893 50.7107 21.3769 50.6279 21.4736ZM49.7217 13.3053C49.7208 13.9485 49.774 14.1835 49.774 14.1835L49.3075 14.4333C49.3075 14.4333 49.298 13.6948 48.7645 13.3362C47.6723 12.6038 45.6699 12.8319 44.5705 9.21347C46.6508 9.37866 48.0069 10.7895 48.5736 11.8534C48.0069 10.7895 47.4164 8.96918 48.435 7.15168C50.8267 10.0816 49.7223 11.9889 49.7217 13.3053ZM45.7434 5.59384C46.8086 6.8669 46.9031 8.6055 46.9031 8.6055C46.9031 8.6055 45.4064 8.13356 44.3818 6.9686C43.4227 5.87609 43.1314 4 43.1314 4C43.1314 4 44.7607 4.42219 45.7434 5.59384Z" fill="url(#paint2_linear_1842_23651)"/>
        <defs>
          <linearGradient id="paint0_linear_1842_23651" x1="7.96875" y1="4" x2="7.96875" y2="36" gradientUnits="userSpaceOnUse">
            <stop stopColor="#7C2D12"/>
            <stop offset="1" stopColor="#FB923C"/>
          </linearGradient>
          <linearGradient id="paint1_linear_1842_23651" x1="28" y1="8" x2="28" y2="32" gradientUnits="userSpaceOnUse">
            <stop stopColor="#7C2D12"/>
            <stop offset="1" stopColor="#FB923C"/>
          </linearGradient>
          <linearGradient id="paint2_linear_1842_23651" x1="48.0312" y1="4" x2="48.0312" y2="36" gradientUnits="userSpaceOnUse">
            <stop stopColor="#7C2D12"/>
            <stop offset="1" stopColor="#FB923C"/>
          </linearGradient>
        </defs>
      </svg>
      }
      <p className="hover:cursor-pointer text-[18px] leading-[26px] font-semibold w-full overflow-ellipsis overflow-x-hidden">{item.title}</p>
</div>
      <div className="flex flex-col gap-1 w-full">
        
        {item.watched && <div className="flex flex-row items-center justify-start gap-1">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.2194 9.32315C12.7244 8.85801 13.1527 8.38944 13.4808 8.00006C13.1527 7.61069 12.7244 7.14211 12.2194 6.67697C10.9481 5.50615 9.45472 4.60006 8 4.60006C6.54528 4.60006 5.05188 5.50615 3.78062 6.67697C3.27558 7.14211 2.84729 7.61069 2.51923 8.00006C2.84729 8.38944 3.27558 8.85801 3.78062 9.32315C5.05188 10.494 6.54528 11.4001 8 11.4001C9.45472 11.4001 10.9481 10.494 12.2194 9.32315ZM8 13.0001C12.1421 13.0001 15.5 8.00006 15.5 8.00006C15.5 8.00006 12.1421 3.00006 8 3.00006C3.85787 3.00006 0.5 8.00006 0.5 8.00006C0.5 8.00006 3.85787 13.0001 8 13.0001ZM10 7.99941C10 9.10398 9.10457 9.99941 8 9.99941C6.89543 9.99941 6 9.10398 6 7.99941C6 6.89484 6.89543 5.99941 8 5.99941C9.10457 5.99941 10 6.89484 10 7.99941Z" fill="#252525" fill-opacity="0.48"/>
          </svg>
          <p className="text-[#939290] text-[12px] leading-[18px] font-medium">Watched</p>
        </div> }
      </div>

      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-3 ">
          {item.channel.logo && <img src={item.channel.logo} alt={item.channel.title} className="h-8 w-8 rounded-[50%]"/>}
          <div>
            <p className="text-[13px] leading-[20px] font-semibold max-w-[150px] overflow-ellipsis overflow-x-hidden">{item.channel.title}</p>
          </div>
        </div>

        {/*<div onClick={() => setIsSaved(!isSaved)} className="cursor-pointer z-10 flex flex-row items-center justify-end gap-1 bg-white border py-[6px] px-3 rounded-[24px] hover:opacity-80">*/}
        {/*  {isSaved ? <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
        {/*    <path d="M3.67057 2.33264H12.3372L13.0039 13.9993L8.00391 11.6322L3.00391 13.9993L3.67057 2.33264Z" fill="#FF5500"/>*/}
        {/*  </svg> : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
        {/*    <path fillRule="evenodd" clipRule="evenodd" d="M12.4673 6V4.13333H14.5007V2.53333H12.4673V0.5H10.8673V2.53333H9.00065V4.13333H10.8673V6H12.4673ZM3.50065 2.5H7.00067V4.1H5.01058L4.58427 11.454L7.34211 10.2085L8.00065 9.91107L8.65919 10.2085L11.417 11.454L11.2168 8H12.8195L13.0628 12.1972L13.1673 14L11.5215 13.2567L8.00065 11.6667L4.47976 13.2567L2.83398 14L2.93849 12.1972L3.50065 2.5Z" fill="#252525"/>*/}
        {/*  </svg>*/}
        {/*  }*/}
        {/*  <p className="text-[13px] leading-5 font-semibold">{isSaved ? "Saved" : "Save"}</p>*/}
        {/*</div>*/}
      </div>
    </div>
    </a>
  )
}

const CategoryPage = () => {
  // External hooks
  const { collectionId } = useParams();
  const dispatch = useDispatch();

  // Data from redux store
  const videos = useSelector(state => state.videos);

  // Local states
  const [categoryTitle, setCategoryTitle] = useState("");

  useEffect(() => {
    if (videos.collections) {
      const currentCategory = getObjectById(collectionId, videos.collections);

      if (currentCategory) {
        setCategoryTitle(currentCategory.title);
      }
    }
  }, [videos.collections])

  useEffect(() => {
    // Request collections if redux store is empty
    if (!videos.collections.length) {
      getCollections(dispatch);
    }

    // Request videos by category if redux store is empty
    if (collectionId && !videos.categories[collectionId]) {
      getVideosByCollection(dispatch, collectionId);
    }
  }, [collectionId]);

  return (
    <div className="w-full h-screen flex flex-col items-center justify-between">
      <div className="w-full">
      <GalleryHeader />
      <div className="w-full flex flex-col items-center">
        <h2 className="self-start xl:self-auto xl:w-[1300px] text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] font-black xl:px-20 md:px-10 px-[20px] md:pt-[60px] pt-[20px] pb-4 md:pb-5">
          {categoryTitle}
        </h2>
        <div className="px-[20px] max-w-[1300px] xl:px-20 md:px-10 py-4 flex flex-wrap flex-row gap-x-2 gap-y-5 items-start justify-start w-full md:ml-0 pb-[40px]">
          {videos.categories[collectionId]?.map((item, index) =>
            <SummariesDiv key={index} item={item} index={index}/>
          )}
        </div>
      </div>
      <GalleryFooter />
      </div>
    </div>
  );
};

export default CategoryPage;
