import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as amplitude from "@amplitude/analytics-browser";

import { EMAIL_PATTERN } from "../utils/constants";
import { mainApi } from "../utils/api";

import { ANALYTICAL_EVENTS } from "../libs/amplitude";

import AuthInputField from "./AuthInputField";
import AuthErrorText from "./AuthErrorText";
import Button from "./Button";
import Spinner from "./Spinner";

import { google } from "../images";

const googleAuth = process.env.REACT_APP_GOOGLEAUTH;

const Auth = ({ title, signup, children }) => {
  // External hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // React-hook-form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: {
    name: searchParams.get('name'),
    email: searchParams.get('email'),
  }});

  // Local states
  const [isLoading, setIsLoading] = useState(false);
  const [authorizationError, setAuthorizationError] = useState(false);

  const onSubmit = (data, e) => {
    e.preventDefault();
    authorize({
      name: data.name?.trim(),
      email: data.email.trim().toLowerCase()
    }).then(() => reset());
  }

  const authorize = async ({ name, email }) => {
    setIsLoading(true);

    mainApi.sendCode({ name: name, email: email })
      .then((res) => {
        signup
          ? amplitude.track(ANALYTICAL_EVENTS.REGISTER_CODE)
          : amplitude.track(ANALYTICAL_EVENTS.LOGIN_CODE);
        setAuthorizationError(false);
        navigate("/verify", {
          state: {
            signup: signup,
            linkId: res.link_id,
            userEmail: email,
          }
        })
      })
      .catch(() => {
        setAuthorizationError(true);
        signup
          ? amplitude.track(ANALYTICAL_EVENTS.REGISTER_ERROR)
          : amplitude.track(ANALYTICAL_EVENTS.LOGIN_ERROR);
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <section className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 flex flex-col items-center justify-center gap-6 w-[360px]">
      <h2 className="text-[24px] font-bold">{title}</h2>
      <a href={googleAuth} className="hover:opacity-60 flex flex-row items-center justify-center rounded-[12px] w-full h-12 gap-2 text-[15px] bg-my-bg-gray font-semibold">
        {google} Continue with Google
      </a>
      <div className="flex flex-row items-center justify-between w-full">
        <div className="h-[1px] bg-gray-300 w-[90px]" />
        <p className="text-center text-[13px] font-medium text-gray-400 w-[157px]">or {title.toLowerCase()} with your email</p>
        <div className="h-[1px] bg-gray-300 w-[90px]" />
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-6 w-full"
      >
        {signup && (
          <AuthInputField
            type="text"
            name="name"
            autoComplete="name"
            placeholder="What’s your name?"
            title="Name"
            register={register('name', {
              required: {
                value: true,
                message: 'You need to input your name'
              },
              maxLength: {
                value: 40,
                message: 'Your name should not contain more than 40 letters'
              },
            })}
            error={errors.name?.message}
          />
        )}
        <AuthInputField
          type="text"
          name="email"
          autoComplete="email"
          placeholder="Enter your email..."
          title="Email"
          register={register('email', {
            required: {
              value: true,
              message: 'You need to input your email'
            },
            pattern: {
              value: EMAIL_PATTERN,
              message: 'Enter valid email address'
            }
          })}
          error={errors.email?.message}
        />
        {authorizationError && (
          <AuthErrorText
            text="An error occurred while sending data"
            customStyle="text-center"
          />
        )}
        <Button type="submit">
          {isLoading ? <Spinner /> : 'Continue with email'}
        </Button>
      </form>
      {children}
    </section>
  );
};

export default Auth;
