const AuthErrorText = ({
  text,
  customStyle
}) => {
  return (
    <p className={`text-xs font-medium text-red w-full ${customStyle}`}>
      {text}
    </p>
  )
}

export default AuthErrorText;