import Cookies from "universal-cookie";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getUserInfo } from "../api/user";
import { checkIsUserAuthorized } from "../utils/helpers";
import { useAuthGetData } from "../hooks/useAuthGetData";
import { mainApi } from "../utils/api";

const cookies = new Cookies();

const ProtectedRoute = () => {
  // External hooks
  const dispatch = useDispatch();

  // Necessary variables
  const isUserAuthorized = checkIsUserAuthorized();
  const refreshToken = cookies.get("refresh_token");
  const currentPage = useLocation().pathname;

  // Data from redux store
  const user = useSelector(state => state.user);

  // Custom hook to get tokens, user data and redirect to the necessary page
  const { requestData, isLoading } = useAuthGetData(mainApi.getRefreshedTokens, currentPage, "/signin");

  useEffect(() => {
    if (!isUserAuthorized && !isLoading && refreshToken) {
      requestData(refreshToken);
    }

    if (isUserAuthorized && !user.currentUser.length) {
      getUserInfo(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isUserAuthorized, refreshToken]);

  if (!isUserAuthorized) {
    if (!refreshToken) {
      return <Navigate to="/signin" />;
    }

    return <div>Загрузка...</div>;
  }

  return <Outlet />;
}

export default ProtectedRoute;