import React, {useMemo} from 'react';

const SubscriptionPlan = ({plan, toggleBorder, checkedPlanId}) => {

  const {id, title, credits_count, discount_price, discount, price} = plan;
  
  const isBorderActive = useMemo(() => {
    return checkedPlanId === id
  }, [checkedPlanId, id])
  
  const handleClick = id => event => {
    event.preventDefault();
    toggleBorder(id);
  }

  return (  
    <div id={id} onClick={handleClick(id)} className={`${isBorderActive && 'border-black'} block border-gray-300 border-[1px] bg-white w-full h-[76px] mb-[12px] px-[20px] py-[16px] rounded-[12px] font-medium text-[15px] leading-[24px] cursor-pointer`}>
        <div className="flex flex-row justify-between">
          <p className="block font-medium text-[15px] leading-[24px]">{title}</p>
          <div className='flex flex-row'>
            <p className="pr-[8px] font-medium text-[15px] leading-[24px] text-gray-500 line-through">{`$${price}`}</p>
            <p className="font-medium text-[15px] leading-[24px]">{`$${discount_price}`}</p>
            <p className="font-medium text-[15px] leading-[24px]">&nbsp;/ mo</p>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <p className="block font-normal text-gray-500 text-[13px] leading-[20px]">{`${credits_count} credits`}</p>
          <p className={`${isBorderActive ? 'text-green-text' : 'text-gray-500'} block font-medium text-[13px] leading-[20px]`}>Save {discount}%</p>
        </div>
    </div>
  );
};

export default SubscriptionPlan;
