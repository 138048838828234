import React from 'react';
import { Link } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";

import { ANALYTICAL_EVENTS } from "../libs/amplitude";

import Auth from "../components/Auth";

const Login = () => {
  amplitude.track(ANALYTICAL_EVENTS.LOGIN);

  return (
    <Auth title="Sign in">
      <div className="text-[13px] font-medium text-gray-500">
        Don't have an account yet?{' '}
        <Link to="/signup" className="text-green hover:opacity-60">
          Sign up
        </Link>
      </div>
    </Auth>
  );
};

export default Login;
