import FBPixel from "react-facebook-pixel";

const FBPixelID = process.env.REACT_APP_FBPIXELID;

export const PixelTrackEvents = {
  registration: "CompleteRegistration",
  startTrial: "StartTrial",
  lead: "Lead",
  initCheckout: "InitiateCheckout",
  purchase: "Purchase",
};

class PixelClass {
  constructor({ fbId }) {
    if (fbId) {
      FBPixel.init(fbId);
      FBPixel.pageView();
    }
  }

  track(title, data) {
    FBPixel.track(title, data);
  }

  registration(data) {
    this.track(PixelTrackEvents.registration, data);
  }
  startTrial(data) {
    this.track(PixelTrackEvents.startTrial, data);
  }
  lead(data) {
    this.track(PixelTrackEvents.lead, data);
  }
  initCheckout(data) {
    this.track(PixelTrackEvents.initCheckout, data);
  }
  purchase(data) {
    this.track(PixelTrackEvents.purchase, data);
  }
}

export const PixelServices = new PixelClass({ fbId: FBPixelID });
