import React from 'react';

const ButtonClose = ({isModal, onClick}) => {
  return (
    <svg onClick={onClick} className={`hover:opacity-60 hover:cursor-pointer ${isModal} && absolute top-[20px] right-[20px]`} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.58716 9.99942L3.46094 4.8732L4.87515 3.45898L10.0014 8.58521L15.1276 3.45898L16.5418 4.8732L11.4156 9.99942L16.5418 15.1257L15.1276 16.5399L10.0014 11.4136L4.87515 16.5399L3.46094 15.1257L8.58716 9.99942Z" fill="#37352F" fillOpacity="0.5"/>
    </svg>
  );
};

export default ButtonClose;
