import React, {useState} from 'react';
import HeaderLanding from "./components/HeaderLanding";
import Promo from "./components/Promo";
import Quote from "./components/Quote";
import Points from "./components/Points";
import {Reviews} from "./components/Reviews";
import {QA} from "./components/QA";
import CTASection from "./components/CTASection";
import Footer from "./components/Footer";
import Video from "./components/Video";
import * as amplitude from "@amplitude/analytics-browser";
import {ANALYTICAL_EVENTS} from "../../libs/amplitude";
import ChatSection from "./components/ChatSection";
import Button from "../../components/Button";
import {Link} from "react-router-dom";
import {logEvent} from "firebase/analytics";
import analytics from "../../libs/googleAnalytics";
import {PixelServices} from "../../libs/pixelServices";

const Landing = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  amplitude.track(ANALYTICAL_EVENTS.LANDING_LAUNCH);

  function handleClick() {
    logEvent(analytics, 'sign_up');
    // logEvent(analytics, 'purchase');
    logEvent(analytics, 'begin_checkout');
    amplitude.track(ANALYTICAL_EVENTS.CTA_BUTTON_MOBILE);
    PixelServices.registration();
    PixelServices.startTrial();
    PixelServices.lead();
    PixelServices.initCheckout();
  }
  
  return (
    <div className="bg-[#F9F8F5]">
      <HeaderLanding isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
      {!isNavOpen && (
        <div>
        <div className="md:px-44 px-[10px] flex flex-col items-center">
        <Promo />
      </div>
        <Video />
      <div className="md:px-44 px-[10px] flex flex-col items-center">
        <Quote />
        <Points />
      </div>
        <ChatSection />
        <Reviews />
      <div className="md:px-44 px-[10px] flex flex-col items-center">
        <QA />
        <CTASection />
      </div>
        <Footer />
          </div>
        )}
      {isNavOpen && (
        <div className="pt-8 h-sidebar px-[20px] flex flex-col gap-2">
          <Link to="/signup" onClick={handleClick}>
            <Button>
              Try Quicks for Mobile
            </Button>
          </Link>
          <Link to="/signin" onClick={() => {amplitude.track(ANALYTICAL_EVENTS.LOGIN_BUTTON)}}>
            <Button light>
              Sign in
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Landing;
