import { NAME } from "../../../utils/constants";
import {Link} from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import {ANALYTICAL_EVENTS} from "../../../libs/amplitude";
import {PixelServices} from "../../../libs/pixelServices";
import Logo from "../../../components/Logo";
import analytics from "../../../libs/googleAnalytics";
import {logEvent} from "firebase/analytics";
import Button from "../../../components/Button";

const HeaderLanding = ({isNavOpen, setIsNavOpen}) => {
  const handleMenuClick = () => {
    setIsNavOpen(!isNavOpen);
  }

  const menuIcon = <svg id="menu" onClick={handleMenuClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M21 7.10151H3V4.70151H21V7.10151ZM21 13.1015H3V10.7015H21V13.1015ZM21 19.1015H3V16.7015H21V19.1015Z" fill="#121212"/>
  </svg>
  const closeIcon = <svg id="close" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.3038 11.9989L4.15234 5.84745L5.8494 4.15039L12.0009 10.3019L18.1523 4.15039L19.8494 5.84745L13.6979 11.9989L19.8494 18.1504L18.1523 19.8474L12.0009 13.696L5.8494 19.8474L4.15234 18.1504L10.3038 11.9989Z" fill="#121212"/>
  </svg>
  
  function handleClick() {
    logEvent(analytics, 'begin_checkout');
    amplitude.track(ANALYTICAL_EVENTS.CTA_BUTTON);
    PixelServices.registration();
    PixelServices.startTrial();
    PixelServices.lead();
    PixelServices.initCheckout();
  }
  
  return (
    <header className="bg-[#F9F8F5] w-full md:px-20 px-[20px] min-h-[72px] flex flex-row items-center justify-between border-b border-gray-300 sticky top-0 left-0 bg-white z-10">
      <Link to="/"  className="flex flex-row gap-2 items-center">
        <Logo width="32px"/>
        <h2 className="text-[15px] font-semibold">{NAME}</h2>
      </Link>
      <div className="flex flex-row gap-2 items-center md:flex hidden">
        <Link to="/signin" onClick={() => {amplitude.track(ANALYTICAL_EVENTS.LOGIN_BUTTON)}}><button className="bg-white border h-[52px] px-4 rounded-[12px] cursor-pointer hover:opacity-60 text-[15px] font-medium">Sign in</button></Link>
        <Link to="/signup" onClick={handleClick}>
          <Button>Try {NAME} now</Button>
        </Link>
      </div>
      <div id="menu" className="md:hidden" onClick={handleMenuClick}>
        {isNavOpen ? closeIcon : menuIcon}
      </div>
    </header>
  );
};

export default HeaderLanding;
