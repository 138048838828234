import React from 'react';
import {art, lamp, planet, search, iconNull} from "../../../images";
import {NAME} from "../../../utils/constants";

const Quote = () => {
  return (
    <p className="md:pt-0 pt-[88px] serif md:text-my text-[34px] leading-[40px] font-bold max-w-[891px] sm:text-center text-start px-10">
      <span className="text-green">{NAME}</span>{iconNull} helps you get the most out of your video viewing, whether it's for learning {lamp} research {search} content creation {art} or just plain enjoyment of learning new things! {planet}
    </p>
  );
};

export default Quote;
