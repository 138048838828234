import React from 'react';
import {NAME, EMAIL, TERMS_URL, PRIVACY_URL} from "../../../utils/constants";
import {ANALYTICAL_EVENTS} from "../../../libs/amplitude";
import Logo from "../../../components/Logo";
import * as amplitude from "@amplitude/analytics-browser";
import Separator from "../../../components/Separator";

const Footer = () => {
  return (
    <div className="w-full">
      <Separator />
    <footer className="md:px-20 px-5 md:h-[72px] h-[146px] flex md:flex-row flex-col gap-4 md:items-center items-start md:justify-between justify-center w-full">
      <div className="flex flex-row items-center gap-[2px]">
        <Logo width="36px" />
        <p className="pl-2 text-sm font-semibold">{NAME}</p>
      </div>
      <div className="flex md:flex-row items-start md:gap-8 gap-4 flex-col">
        <nav className="flex flex-row items-center gap-6 md:justify-self-end">
          <a href={TERMS_URL} onClick={() => amplitude.track(ANALYTICAL_EVENTS.TERMS)} target="_blank" rel="noopener noreferrer" className="text-sm font-bold hover:opacity-60">Terms of Use</a>
          <a href={PRIVACY_URL} onClick={() => amplitude.track(ANALYTICAL_EVENTS.PRIVACY)} target="_blank" rel="noopener noreferrer" className="text-sm font-bold hover:opacity-60">Privacy Policy</a>
          <a href={EMAIL} onClick={() => amplitude.track(ANALYTICAL_EVENTS.CONTACT)} className="text-sm font-bold hover:opacity-60">Contact Us</a>
        </nav>
        <p className="text-sm text-gray-400 font-medium">&#169;&nbsp;{new Date().getFullYear()} Purple Studio HQ Ltd, Limassol, Cyprus</p>
      </div>
    </footer>
    </div>
  );
};

export default Footer;
