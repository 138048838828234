import { rollbar } from "../providers/RollbarProvider";

const airtableKey = process.env.REACT_APP_AIRTABLE_API;
const Authorization = `Bearer ${airtableKey}`

class Airtable {
  constructor({ baseUrl, headers }) {
    this._baseUrl = baseUrl;
    this._headers = headers;
  }

  _processCaughtError(message, error) {
    rollbar.error(message, error ? error : undefined);
  }

  async _checkServerResponse(res) {
    const result = await res.json();

    if (res.ok) {
      return result.records;
    } else {
      this._processCaughtError(result.message);
      
      return Promise.reject(result.message);
    }
  }

  sendFeedback({ answers, feedback }) {
    return fetch(`${this._baseUrl}/feedback`, {
      method: "POST",
      headers: this._headers,
      body: JSON.stringify({
        "records": [
          {
            "fields": {
              "feedback": feedback,
              "checkbox": answers,
            }
          }
        ]
      }),
    })
    .then(this._checkServerResponse)
    .catch((error) => this._processCaughtError('Error sending feedback', error));
  }
  getOnboardingData() {
    return fetch(`${this._baseUrl}/onboardings`, {
      method: "GET",
      headers: this._headers,
    })
      .then(this._checkServerResponse)
      .catch((error) => this._processCaughtError('Error getting onboarding data', error));
  }
  getPagesData() {
    return fetch(`${this._baseUrl}/pages`, {
      method: "GET",
      headers: this._headers,
    })
      .then(this._checkServerResponse)
      .catch((error) => this._processCaughtError('Error getting pages data', error));
  }
}

const AirtableApi = new Airtable({
  baseUrl: 'https://api.airtable.com/v0/appdkdmgGFJ7GjteA', 
  headers: {
    "Authorization": Authorization,
    "Content-Type": "application/json",
  }
})

class MainApi {
  constructor({ baseUrl, headers }) {
    this._baseUrl = baseUrl;
    this._headers = headers;
  }

  _processCaughtError(message, error) {
    rollbar.error(message, error ? error : undefined);
  }

  async _checkServerResponse(res) {
    const result = await res.json();

    if (res.ok) {
      return result;
    } else {
      this._processCaughtError(result.message);
      
      return Promise.reject(result.message);
    }
  }

  // User endpoints

  sendCode({ name, email }) {
    return fetch(`${this._baseUrl}/magic_links/create`, {
      method: "POST",
      headers: this._headers,
      body: JSON.stringify({ name: name, email: email }),
      credentials: "include",
    })
      .then(this._checkServerResponse)
      .catch((error) => this._processCaughtError('Error sending verification code', error));
  }

  verify({ linkId, code }) {
    return fetch(`${this._baseUrl}/magic_links/${linkId}/authorize?code=${code}`, {
      method: "GET",
      headers: this._headers,
      credentials: "include",
    })
      .then(this._checkServerResponse)
      .catch((error) => this._processCaughtError('Error verifying the code', error));
  }

  getRefreshedTokens(refreshToken) {
    return fetch(` /api/v1/users/refresh_token`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': refreshToken
      },
    })
      .then(this._checkServerResponse)
      .catch((error) => this._processCaughtError('Error getting refreshed tokens', error));
  }
  
  getUserInfo() {
    return fetch(`${this._baseUrl}/users/me`, {
      method: "GET",
      headers: {
        ...this._headers,
        // Authorization: `Bearer ${remember_me_token}`,
      },
      credentials: "include",
    })
      .then(this._checkServerResponse)
      .catch((error) => this._processCaughtError('Error getting user info', error));
  }
  
  logout() {
    return fetch(`${this._baseUrl}/user_sessions/logout`, {
      method: "POST",
      headers: {
        ...this._headers,
      },
      credentials: "include",
    })
      .then(this._checkServerResponse)
      .catch((error) => this._processCaughtError('Error logging out user', error));
  }

  // Plans endpoints

  getPlans({page}) {
    return fetch(`${this._baseUrl}/products/list.json?page=${page}`, {
      method: "GET",
      headers: {
        ...this._headers,
        // Authorization: `Bearer ${remember_me_token}`,
      },
      credentials: "include",
    })
      .then(this._checkServerResponse)
      .catch((error) => this._processCaughtError('Error getting plans', error));
  }

  // Gallery endpoints

  getCollections() {
    return fetch(`${this._baseUrl}/library_collections`, {
      method: "GET",
      headers: {
        ...this._headers
      },
      credentials: "include",
    })
      .then(this._checkServerResponse)
      .catch((error) => this._processCaughtError('Error getting collections', error));
  }

  // Videos endpoints
  
  getFreeVideos() {
    return fetch(`${this._baseUrl}/videos/list_for_demo.json`, {
      method: "GET",
      headers: {
        ...this._headers
      },
      credentials: "include",
    })
      .then(this._checkServerResponse)
      .catch((error) => this._processCaughtError('Error getting free videos', error));
  }

  getChannelsPics() {
    return fetch(`${this._baseUrl}/video_channels/for_trial1.json`, {
      method: "GET",
      headers: {
        ...this._headers
      },
      credentials: "include",
    })
      .then(this._checkServerResponse)
      .catch((error) => this._processCaughtError('Error getting channel pics', error));
  }

  getVideosByCollection(id) {
    return fetch(`${this._baseUrl}/videos/by_collection/${id}`, {
      method: "GET",
      headers: {
        ...this._headers
      },
      credentials: "include",
    })
      .then(this._checkServerResponse)
      .catch((error) => this._processCaughtError('Error getting videos by collection', error));
  }
  
  getRecentVideos() {
    return fetch(`${this._baseUrl}/videos/recent/`, {
      method: "GET",
      headers: {
        ...this._headers
      },
      credentials: "include",
    })
      .then(this._checkServerResponse)
      .catch((error) => this._processCaughtError('Error getting recent videos', error));
  }
  
  submitVideo({url}) {
    return fetch(`${this._baseUrl}/videos/`, {
      method: "POST",
      headers: {
        ...this._headers
      },
      body: JSON.stringify({ url: url }),
      credentials: "include",
    });
  }
}

const mainApi = new MainApi({
  baseUrl: "https://app.quicks.ai/api/v1",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export { AirtableApi, mainApi }