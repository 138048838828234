import * as amplitude from "@amplitude/analytics-browser";
import {NAME} from "../utils/constants";
import Logo from "../components/Logo";
import React, {useEffect, useState} from "react";
import {ANALYTICAL_EVENTS} from "../libs/amplitude";
import extensionPin from "../images/extentionPin.svg";
import {puzzleLogo} from "../images";
import youTube from "../images/youTube.svg";
import { getFreeVideos } from "../api/videos";
import { useDispatch, useSelector } from "react-redux";

const VideoImitation = ({data}) => {
  const {title, channel_title, thumbnail_url, url} = data;
  return (
    <main className='w-[240px] gap-[24px]'>
      <a className='relative hover:opacity-60 transition-opacity' href={url} target='_blank' rel="noreferrer">
        <img className='absolute top-[60px] left-[95px]' src={youTube} alt='YouTube Logo'/>
        <img className='w-[240px] h-[140px] mb-[8px] rounded-[5px] object-cover' src={thumbnail_url} alt='YouTube poster'/>
      </a>
      <p className='font-medium text-[12px] leading-[16px]'>{title}</p>
      {channel_title && <p className='font-medium text-button-gray/[0.45] text-[12px] leading-[16px]'>{channel_title}</p>}
    </main>
  )
};

const HowTo = () => {
  amplitude.track(ANALYTICAL_EVENTS.ONBOARDING_INSTRUCTION);

  // External hooks
  const dispatch = useDispatch();

  // Data from redux store
  const videos = useSelector(state => state.videos);

  useEffect(() => {
    // Request free videos from the server if redux store is empty
    if (!videos.free.length) {
      getFreeVideos(dispatch);
    }
  }, []);

  return (
    <>
      <section className="page relative flex flex-1 flex-col items-center">
        <header className='self-start justify-self-start flex items-center my-[14px] mx-[20px]'>
          <Logo width='32px'></Logo>
          <p className='font-semibold text-[15px] leading-[24px] ml-[8px]'>{NAME}</p>
        </header>
        <img src={extensionPin} className="fixed top-[0px] right-[0px]" alt='Pin element'/>
        <h1 className='serif text-center mt-10 mb-[22px] font-black text-[32px] leading-[40px]'>How to use extension</h1>
        <ol className="z-[1]">
          <li type="1" className="relative list-none bg-white border mb-[8px] w-[504px] px-[20px] py-[16px] rounded-[12px]">
            <article>
              <p className="absolute left-[20px] font-medium text-[13px] leading-[20px]">1.</p>
              <h3 className="block pl-[18px] mb-[4px] font-semibold text-[13px] leading-[20px]">Open any video on Youtube</h3>
              <p className="block pl-[18px] text-button-gray/[0.45] font-medium text-[12px] leading-[16px]">For now, {NAME} supports only English videos</p>
            </article>
          </li>
          <li type="1" className="relative list-none bg-white border mb-[8px] w-[504px] px-[20px] py-[16px] rounded-[12px]">
            <article>
              <p className="absolute left-[20px] font-medium text-[13px] leading-[20px]">2.</p>
              <h3 className="block pl-[18px] mb-[4px] font-semibold text-[13px] leading-[20px]">Run the extension</h3>
              <p className="block pl-[18px] text-button-gray/[0.45] font-medium text-[12px] leading-[16px]">You can find your plugin by clicking on the extension {puzzleLogo} button <br /> in the top right corner of your browser</p>
            </article>
          </li>
          <li type="1" className="relative list-none bg-white border mb-[8px] w-[504px] px-[20px] py-[16px] rounded-[12px]">
            <article>
              <p className="absolute left-[20px] font-medium text-[13px] leading-[20px]">3.</p>
              <h3 className="block pl-[18px] mb-[4px] font-semibold text-[13px] leading-[20px]">Click “Summarize” to get your summary</h3>
              <p className="block pl-[18px] text-button-gray/[0.45] font-medium text-[12px] leading-[16px]">The process will take 1-2 minutes</p>
            </article>
          </li>
        </ol>
        <h2 className="font-semibold text-[15px] leading-[24px] mb-[16px] mt-[24px]">Try summarize these videos for free</h2>
        <div className="grid grid-row grid-cols-2 gap-[24px]">
          {videos.free.map((video, index) => <VideoImitation key={index} data={video}/>)}
        </div>
      </section>
    </>
  );
}
  
  export default HowTo;