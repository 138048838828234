import * as amplitude from "@amplitude/analytics-browser";
import {DOWNLOAD_LINK, NAME} from "../../utils/constants";
import Logo from '../../components/Logo';
import InstallModal from "../../components/InstallModal";
import {ANALYTICAL_EVENTS} from "../../libs/amplitude";
import Button from "../../components/Button";


const Download = () => {
    amplitude.track(ANALYTICAL_EVENTS.ONBOARDING_DOWLOAD_PAGE);
    
    function handleClick(event) {
      event.preventDefault()
      window.open(DOWNLOAD_LINK, '_blank');
      amplitude.track(ANALYTICAL_EVENTS.ONBOARDING_DOWLOAD_BTN);
    }

    return (
      <div className="relative page">
        <header className='flex items-center my-[14px] mx-[20px]'>
          <Logo width='32px'></Logo>
          <p className='font-semibold text-[15px] leading-[24px] ml-[8px]'>{NAME}</p>
        </header>
        <section className="flex flex-1 flex-col items-center justify-center px-[20px]">
          <h1 className="serif text-center mb-[24px] font-black text-[24px] leading-[32px]">Download extension from <br />Chrome Store</h1>
          <Button onClick={handleClick} style="max-w-[360px]">
            Get Extension
          </Button>
        </section>
        <InstallModal />
      </div>
    );
  };
  
  export default Download;