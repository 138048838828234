import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import userReducer from "./user.slice";
import videosReducer from "./videos.slice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    videos: videosReducer
  },
});

setupListeners(store.dispatch);