import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentUser: {}
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetCurrentUser: (state, { payload }) => {
      state.currentUser = payload;
    },
    changeCurrentUserValue: (state, { payload }) => {
      state[payload.name] = payload.value;
    },
  },
})

export const {
  resetCurrentUser,
  changeCurrentUserValue
} = userSlice.actions

export default userSlice.reducer